import React from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";

const UserRoles = (props) => {
    const { keyId, data: role, HCClasses } = props;
    return (
        <Grid key={keyId} item xs={2} className={HCClasses.paddingZero}>
            <Typography variant="body2"><Checkbox checked={true} readOnly color="primary" />{role.name}</Typography>
        </Grid>
    )
};

export default UserRoles;