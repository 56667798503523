import React from "react";
import * as cookie from "js-cookie";
import objectIsEmpty from '../../../design-system/utils/objectIsEmpty';

export const SESSION_COOKIE = "session";
export const TMP_LOGIN_START_COOKIE = "TMP_LOGIN_START";

export const setSessionCookie = (session) => {
    // Delete all cookies
    localStorage.clear();
    sessionStorage.clear();
    Object.keys(cookie.get()).map(_cookie => cookie.remove(_cookie));
    
    // Set session cookie. Bi-directional login in subdomains.
    if ((window.location.host).includes(".talisis.com")) {
        cookie.set(SESSION_COOKIE, JSON.stringify(session), { domain: ".talisis.com" });
        if (!session["ignore_admin_talisis_cookie"]) {
            cookie.set("LOGIN_ADMIN_TALISIS", true, { domain: ".talisis.com" });
        }
    }
    else {
        cookie.set(SESSION_COOKIE, JSON.stringify(session));
        if (!session["ignore_admin_talisis_cookie"]) {
            cookie.set("LOGIN_ADMIN_TALISIS", true);
        }
    }

    localStorage.removeItem(SESSION_COOKIE);
    localStorage.setItem(SESSION_COOKIE, JSON.stringify(session));
};

export const getSessionCookie = () => {
    const sessionCookie = cookie.get(SESSION_COOKIE);
    if (sessionCookie === undefined || sessionCookie === null) {
        return {};
    }
    else {
        return JSON.parse(sessionCookie);
    }
};

export const removeSessionCookie = (reload = false) => {
    if ((window.location.host).includes(".talisis.com")) {
        cookie.remove(SESSION_COOKIE, { domain: ".talisis.com" });
        cookie.remove("LOGIN_ADMIN_TALISIS", { domain: ".talisis.com" });
        cookie.remove("LOGIN_PLATFORM_TALISIS", { domain: ".talisis.com" });
    }
    else {
        cookie.remove(SESSION_COOKIE);
        cookie.remove("LOGIN_ADMIN_TALISIS");
        cookie.remove("LOGIN_PLATFORM_TALISIS");
    }

    localStorage.clear();
    sessionStorage.clear();
    if (reload) {
        window.location.reload();
    }
};

export const userIsLoggedIn = () => {
    const session = getSessionCookie();
    const isLogged = !objectIsEmpty(session);
    return isLogged;
};

export const SessionContext = React.createContext(getSessionCookie());

export const getCookie = (name) => cookie.get(name);
export const setCookie = (name, value) => cookie.set(name, String(value));
export const removeCookie = (name) => cookie.remove(name);