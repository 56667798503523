import React from 'react';
import { Box, Typography, makeStyles } from "@material-ui/core";
import { ACC_UNIF_REQUEST_STATUS } from '../../HealthCheckHelpers/Status';

const useStyles = makeStyles((theme) => ({
    title: {
        ...theme.typography.bodyTextSmallRegular,
    },
    separator: {
        border: `1px solid ${theme.palette.colors.fillStroke[50]}`,
        width: "100%",
    },
    label: {
        ...theme.typography.bodyTextSmallSemiBold,
    },
    icon: {
        ...theme.typography.bodyTextLargeSemiBold,
        display: "flex",
    },
}));

const AccountUnification = ({ HCClasses, status }) => {
    const classes = useStyles();

    return (
        <Box className={`mt-3 ${HCClasses.box}`}>
            <Typography variant="h4">Unificación de cuentas</Typography>
            <Typography className={`${classes.title} text-dark-black-200`}>Muestra el estado de solicitud de unificación de cuentas (solo aplicable para el caso de cuentas duplicadas) "No aplica", "Pendiente", "En proceso", "Unificado".</Typography>
            <hr className={classes.separator} />
            <Typography className={`${classes.label} text-dark-black-200`}>Estado de solicitud:</Typography>
            <Typography className={`${classes.icon} text-violet-300`}>
                {ACC_UNIF_REQUEST_STATUS.IN_PROCESS === status && <i className="ri-time-line font-size-20 mr-1"></i>}
                {ACC_UNIF_REQUEST_STATUS.NOT_APPLY === status && <i className="ri-close-line font-size-20 mr-1"></i>}
                {ACC_UNIF_REQUEST_STATUS.PENDING === status && <i className="ri-time-line font-size-20 mr-1"></i>}
                {ACC_UNIF_REQUEST_STATUS.UNIFIED === status && <i className="ri-check-line font-size-20 mr-1"></i>}
                {status}
            </Typography>
        </Box>
    )
};

export default AccountUnification;