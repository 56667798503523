import React from 'react';
import clsx from 'clsx';

// Material components
import { Box, Icon, Typography } from '@material-ui/core';

const ItemStatusTable = ({ statusId }) => {
    let icon;
    let color;
    let text;

    if (statusId === 1) {
        icon = "ri-checkbox-circle-fill";
        color = "text-electric-green-400";
        text = "Publicado";
    } else if (statusId === 2) {
        icon = "ri-save-2-fill";
        color = "text-blue-400";
        text = "Borrador";
    } else {
        icon = "ri-archive-line";
        color = "text-dark-black-200";
        text = "Archivado";
    }
    return (
        <Box display="flex" gridGap={8}>
            <Icon className={clsx(icon, "font-size-16", color)} />
            <Typography variant='caption' className={clsx("semi-bold", color)}>{text}</Typography>
        </Box>
    );
};

export default ItemStatusTable;