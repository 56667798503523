import React from 'react';
import {
    Box,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Grid,
    Tooltip,
    makeStyles
} from "@material-ui/core";
import Select from '../../../../design-system/components/Form/Select/Select';
import { getLogoBySource } from '../../../../design-system/utils/getLogo';
import { convertSourceToImageResource } from '../../../../design-system/utils/placeholderImages';

const OU_LOGOS = {
    "UNID": getLogoBySource(9, "white"),
    "UERRE": getLogoBySource(8, "white"),
};

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundSize: "100%",
        backgroundPositionY: "center",
        backgroundRepeat: "no-repeat",
        fontSize: "18px",
        fontWeight: 600,
        height: "64px",
        lineHeight: "64px",
        padding: 0,
        textAlign: "center",
    },
    cardMediaImage: {
        transform: "scale(0.6)",
        verticalAlign: "middle",
    },
    filter: {
        alignItems: "center",
        display: "flex",
        position: "absolute",
        right: "16px",
        width: "30%",
    },
    filterLabel: {
        ...theme.typography.bodyTextSmallSemiBold,
    },
    courseName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        whiteSpace: "nowrap",
        ...theme.typography.bodyTextSmallSemiBold,
    },
    courseId: {
        ...theme.typography.bodyTextSmallSemiBold,
    },
    courseInfo: {
        ...theme.typography.bodyTextXsmallRegular,
        wordBreak: "break-word",
        "& ul": {
            paddingInlineStart: "24px",
        }
    },
}));

const CoursesInProgress = (props) => {
    const { data } = props;
    const classes = useStyles();

    const hasPrograms = data.programs && data.programs.length > 0 ? true : false;
    const hasEduconCourses = data.courses && data.courses.educon && data.courses.educon.length > 0 ? true : false;
    const hasAlliesCourses = data.courses && data.courses.allies && data.courses.allies.length > 0 ? true : false;

    const [filterSelected, setFilterSelected] = React.useState("all");
    return (
        <React.Fragment>
            <Box className={classes.filter}>
                <Typography variant="body2" className={`${classes.filterLabel} text-dark-black-200`}>Filtrar:</Typography>
                <Select
                    value={filterSelected}
                    onChange={(e) => {
                        setFilterSelected(e.target.value);
                    }}
                    name="filter"
                    items={[
                        { id: "all", label: "Todos" },
                        { id: "programs", label: "Programas", disabled: !hasPrograms, },
                        { id: "educon", label: "Educación continua", disabled: !hasEduconCourses, },
                        { id: "allies", label: "Aliados", disabled: !hasAlliesCourses, },
                    ]}
                    className="ml-2"
                />
            </Box>
            <Grid container spacing={2}>
                {
                    !hasPrograms && !hasEduconCourses && !hasAlliesCourses ?
                        <Grid item xs={12}><Typography variant="body2" className="text-dark-black-200">Sin información para mostrar</Typography></Grid>
                        :
                        <React.Fragment>
                            {
                                (filterSelected === "all" || filterSelected === "programs") && data.programs && data.programs.length > 0 &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" className="text-violet-300" style={{ width: "100%" }}>Programas</Typography>
                                    </Grid>
                                    {
                                        data.programs.map(program => (
                                            <React.Fragment key={`hc_fragment_program_${program.id}_item`}>
                                                <Grid item xs={12}><Typography variant="subtitle2" className="bold">{program.program_name}</Typography></Grid>
                                                {
                                                    program.courses && program.courses.length > 0 ?
                                                        program.courses.map(course => (
                                                            <Grid key={`hc_grid_program_course_${course.course_id}_item`} item xs={4}>
                                                                <Card>
                                                                    <CardHeader className={classes.cardHeader} subheader={<img src={OU_LOGOS[course.company_id]} className={classes.cardMediaImage} alt={course.company_id} />} style={{ backgroundImage: `url("${convertSourceToImageResource(course.company_id, course.source_id)}"` }} />
                                                                    <CardContent>
                                                                        <Tooltip title={course.course_name} interactive>
                                                                            <Typography variant="body2" className={classes.courseName}>{course.course_name}</Typography>
                                                                        </Tooltip>
                                                                        <Typography variant="body2" className={`text-violet-300 ${classes.courseId}`}>id: {course.course_id}</Typography>
                                                                        <Typography variant="body2" component="span" className={`${classes.courseInfo} text-dark-black-200`}>
                                                                            <ul>
                                                                                <li>course_url:&nbsp;{course.courses_url_neo || "(Sin valor)"}</li>
                                                                                <li>third_party_id: {course.third_party_id || "(Sin valor)"}</li>
                                                                                <li>modality_id: {course.modality_id === 1 ? "Presencial" : "Online"}</li>
                                                                            </ul>
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        ))
                                                        :
                                                        <Grid item xs={12}><Typography variant="body2" className="text-dark-black-200">Sin información para mostrar</Typography></Grid>
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </React.Fragment>
                            }
                            {
                                (filterSelected === "all" || filterSelected === "educon") && data.courses && data.courses.educon && data.courses.educon.length > 0 &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" className="text-violet-300" style={{ width: "100%" }}>Educación continua</Typography>
                                    </Grid>
                                    {
                                        data.courses.educon.map(coursesByTenant => (
                                            coursesByTenant.courses.map(course => (
                                                <Grid key={`hc_grid_educon_course_${course.course_id}_item`} item xs={4}>
                                                    <Card>
                                                        <CardHeader className={classes.cardHeader} subheader={<img src={OU_LOGOS[course.company_id]} className={classes.cardMediaImage} alt={course.company_id} />} style={{ backgroundImage: `url("${convertSourceToImageResource(course.company_id, course.source_id)}"` }} />
                                                        <CardContent>
                                                            <Tooltip title={course.name} interactive>
                                                                <Typography variant="body2" className={classes.courseName}>{course.name}</Typography>
                                                            </Tooltip>
                                                            <Typography variant="body2" className={`text-violet-300 ${classes.courseId}`}>id: {course.course_id}</Typography>
                                                            <Typography variant="body2" component="span" className={`${classes.courseInfo} text-dark-black-200`}>
                                                                <ul>
                                                                    <li>course_url:&nbsp;{course.course_url || course.course_url_neo || "(Sin valor)"}</li>
                                                                    <li>third_party_id: {course.third_party_id || "(Sin valor)"}</li>
                                                                    <li>modality_id: {course.modality_id === 1 ? "Presencial" : "Online"}</li>
                                                                </ul>
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))
                                        ))
                                    }
                                </React.Fragment>
                            }
                            {
                                (filterSelected === "all" || filterSelected === "allies") && data.courses && data.courses.allies && data.courses.allies.length > 0 &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" className="text-violet-300" style={{ width: "100%" }}>Aliados</Typography>
                                    </Grid>
                                    {
                                        data.courses.allies.map(coursesByTenant => (
                                            coursesByTenant.courses.map(course => (
                                                <Grid key={`hc_grid_allies_course_${course.course_id}_item`} item xs={4}>
                                                    <Card>
                                                        {/* subheader={<img src={getLogoBySource(coursesByTenant.id, (coursesByTenant.id === 12 ? "whiteColor" : "white"))} className={classes.cardMediaImage} alt={coursesByTenant.id} />} */}
                                                        <CardHeader className={classes.cardHeader} style={{ backgroundImage: `url("${convertSourceToImageResource(course.company_id, course.source_id)}"` }} />
                                                        <CardContent>
                                                            <Tooltip title={course.name} interactive>
                                                                <Typography variant="body2" className={classes.courseName}>{course.name}</Typography>
                                                            </Tooltip>
                                                            <Typography variant="body2" className={`text-violet-300 ${classes.courseId}`}>id: {course.course_id}</Typography>
                                                            <Typography variant="body2" component="span" className={`${classes.courseInfo} text-dark-black-200`}>
                                                                <ul>
                                                                    <li>course_url:&nbsp;{course.course_url || "(Sin valor)"}</li>
                                                                    <li>third_party_id: {course.third_party_id || "(Sin valor)"}</li>
                                                                    <li>modality_id: {course.modality_id === 1 ? "Presencial" : "Online"}</li>
                                                                </ul>
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))
                                        ))
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                }
            </Grid>
        </React.Fragment>
    )
};

export default CoursesInProgress;