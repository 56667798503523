import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    Tooltip,
    CircularProgress,
    makeStyles
} from "@material-ui/core";

import { ACTIONS } from '../../HealthCheckHelpers/Actions';
import Toast from '../../../../../design-system/components/Toast/Toast';

const useStyles = makeStyles((theme) => ({
    title: {
        ...theme.typography.bodyTextSmallRegular,
    },
    titleCourseName: {
        ...theme.typography.bodyTextLargeSemiBold,
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        whiteSpace: "nowrap",
        width: "100%",
    },
}));

const FixNeoAssignments = ({ data, setData, HCClasses, HealthCheckService, }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const defaultToastProps = { open: false, severity: "success", message: "" };
    const [toastProps, setToastProps] = React.useState(defaultToastProps);
    const [currentCourseIdToFix, setCurrentCourseIdToFix] = React.useState(null);

    const handleFixClassNeo = async (idxData, personData, courseData) => {
        setLoading(true);
        setCurrentCourseIdToFix(courseData.course_id);

        let errorMessage = null;
        const emailsIsUsedForLogin = personData.emails.filter(email => email.is_main && email.is_used_for_login);

        if (emailsIsUsedForLogin.length === 0) {
            errorMessage = "No es posible reparar la conexión ya que el usuario no cuenta con un correo para hacer login en talisis.com y este es requerido";
            setToastProps({ ...toastProps, open: true, severity: "error", message: errorMessage, });
        }
        else if (emailsIsUsedForLogin.length > 1) {
            errorMessage = "No es posible reparar la conexión ya que el usuario cuenta con múltiples correos para hacer login en talisis.com";
            setToastProps({ ...toastProps, open: true, severity: "error", message: errorMessage, });
        }

        if (errorMessage) {
            return;
        }

        try {
            await HealthCheckService.invoke(ACTIONS.FIX_CLASS_NEO, {
                body: {
                    "course_id": courseData.course_id,
                    "course_session_id": courseData.course_session_id,
                    "company_id": courseData.company_id,
                    "level_id": courseData.level_id,
                    "source_id": courseData.source_id,
                    "person_id": personData.person_id,
                    "first_name": personData.first_name,
                    "last_name": personData.last_name,
                    "email": emailsIsUsedForLogin[0].email_address,
                    "matricula": personData.user_id,
                    "pidm": personData.external_id
                }
            })
                .then(({ success, message, errors }) => {
                    if (success) {
                        if (message) {
                            setToastProps({ open: true, severity: "error", message: message, });
                        }
                        else if (errors.length > 0) {
                            setToastProps({ open: true, severity: "error", message: errors.join(", "), });
                        }
                        else {
                            if (courseData.courses_url_neo) {
                                data[idxData].neo_user_courses_data[0][courseData.company_id].push({ id: parseInt(courseData.courses_url_neo.split("/").pop()) });
                                setData(data);
                            }
                            else if (courseData.course_url_neo) {
                                data[idxData].neo_user_courses_data[0][courseData.company_id].push({ id: parseInt(courseData.course_url_neo.split("/").pop()) });
                                setData(data);
                            }
                            else {
                                setToastProps({ open: true, severity: "error", message: "Curso sin configuración de URL NEO.", });
                            }
                        }
                    }
                    else {
                        setToastProps({ open: true, severity: "error", message: message, });
                    }
                })
                .catch(error => {
                    setToastProps({ open: true, severity: "error", message: error, });
                });
        }
        catch (error) {
            setToastProps({ open: true, severity: "error", message: error, });
        }

        setLoading(false);
        setCurrentCourseIdToFix(null);
    };

    const onErrorMessage = () => {
        setToastProps(defaultToastProps);
        setLoading(false);
    };

    return (
        <Box className={`mt-3 ${HCClasses.box}`}>
            <Typography variant="h4">Enrolamiento de clases NEO</Typography>
            <Typography className={`${classes.title} text-dark-black-200`}>Apartado únicamente donde se muestra la lista de clases en progreso y se verifica en NEO y el enrolamiento de esa clase, tanto en Talisis.com como NEO.</Typography>
            {
                data && data.length > 1 ? 
                <React.Fragment>
                    <hr className={HCClasses.separator} />
                    <Typography variant="body2" className="bold"><i className="ri-alert-fill text-error-300 mr-1"></i>Usuario requiere análisis de unificación de cuentas.</Typography>
                </React.Fragment>
                :
                data && data.map((_data, idx) => {
                    const hasPrograms = _data.programs && _data.programs.programs && _data.programs.programs.length > 0 ? true : false;
                    const hasEduconCourses = _data.courses && _data.courses.educon && _data.courses.educon.length > 0 ? true : false;
                    let hasCoursesOfProgramsUerre = false;
                    let hasCoursesOfProgramsUnid = false;
                    let hasCoursesEduconUerre = false;
                    let hasCoursesEduconUnid = false;

                    if (hasPrograms) {
                        const asyncPrograms = _data.programs.programs.filter(program => (program.learning_style === "asincronico"));
                        if (asyncPrograms.length > 0) {
                            asyncPrograms.map(asyncProgram => {
                                if (asyncProgram.courses.length > 0) {
                                    hasCoursesOfProgramsUerre = asyncProgram.courses.filter(course => course.company_id === "UERRE").length > 0 ? true : false;
                                    hasCoursesOfProgramsUnid = asyncProgram.courses.filter(course => course.company_id === "UNID").length > 0 ? true : false;
                                }
                            });
                        }
                    }

                    if (hasEduconCourses) {
                        _data.courses.educon.map(coursesByTenant => {
                            if (coursesByTenant.courses.length > 0) {
                                if (coursesByTenant.id === "UERRE") {
                                    hasCoursesEduconUerre = coursesByTenant.courses.filter(course => course.source_id === 1 && course.modality_id === 2 && course.company_id === "UERRE").length > 0 ? true : false;
                                }
                                else if (coursesByTenant.id === "UNID") {
                                    hasCoursesEduconUnid = coursesByTenant.courses.filter(course => course.source_id === 1 && course.modality_id === 2 && course.company_id === "UNID").length > 0 ? true : false;
                                }
                            }
                        });
                    }
                    
                    return (
                        <React.Fragment key={`hc_fragment_fix_neo_assignments_person_id_${_data.id}`}>
                            <Typography variant="h4" className="text-violet-300 mt-2">Cursos UERRE</Typography>
                            <hr className={HCClasses.separator} />
                            {
                                hasCoursesOfProgramsUerre || hasCoursesEduconUerre ?
                                    <Grid container spacing={2}>
                                        {
                                            hasCoursesOfProgramsUerre && _data.programs.programs.map(program => program.learning_style === "asincronico" && (
                                                program.courses.map(course => course.company_id === "UERRE" && (
                                                    <Grid key={`hc_grid_program_course_uerre_${course.course_id}_item`} item xs={4}>
                                                        <Card>
                                                            <CardContent>
                                                                <Tooltip title={course.course_name} interactive>
                                                                    <Typography variant="body1" className={classes.titleCourseName}>{course.course_name}</Typography>
                                                                </Tooltip>
                                                                <Typography variant="body1" className="mt-2" align="center" component="div">
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={5} className={HCClasses.textAlignRight}>Talisis <i className="ri-checkbox-circle-fill text-violet-300"></i></Grid>
                                                                        <Grid item xs={2}>-</Grid>
                                                                        <Grid item xs={5} className={HCClasses.textAlignLeft}>NEO
                                                                            {(() => {
                                                                                let result = <i className="ri-close-circle-fill text-error-300 ml-1"></i>;
                                                                                course.exists_in_neo = false;
                                                                                for (let neo_course of _data.neo_user_courses_data[0][course.company_id]) {
                                                                                    if (course.courses_url_neo && course.courses_url_neo.includes(neo_course.id)) {
                                                                                        course.exists_in_neo = true;
                                                                                        result = <i className="ri-checkbox-circle-fill text-violet-300 ml-1"></i>;
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                return result;
                                                                            })()}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Typography>
                                                                <Button variant="contained" color="default" size="small" className="mt-2" fullWidth onClick={async () => {
                                                                    await handleFixClassNeo(idx, { "person_id": _data.id, "first_name": _data.first_name, "last_name": _data.last_name, "emails": _data.emails, "matricula": _data.user_id, "pidm": _data.external_id }, course);
                                                                }}
                                                                    disabled={course.exists_in_neo || (loading && course.course_id === currentCourseIdToFix) ? true : false}>
                                                                    {loading && course.course_id === currentCourseIdToFix ? <CircularProgress className={HCClasses.loaderButton} /> : (course.exists_in_neo ? <React.Fragment><i className="ri-check-fill mr-2"></i>Conexión reparada</React.Fragment> : <React.Fragment><i className="ri-refresh-line mr-2"></i>Reparar conexión</React.Fragment>)}
                                                                </Button>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            ))
                                        }
                                        {
                                            hasCoursesEduconUerre && _data.courses.educon.map(coursesByTenant => (
                                                coursesByTenant.courses.map(course => course.source_id === 1 && course.modality_id === 2 && course.company_id === "UERRE" && (
                                                    <Grid key={`hc_grid_educon_course_uerre_${course.course_id}_item`} item xs={4}>
                                                        <Card>
                                                            <CardContent>
                                                                <Tooltip title={course.name} interactive>
                                                                    <Typography variant="body1" className={classes.titleCourseName}>{course.name}</Typography>
                                                                </Tooltip>
                                                                <Typography variant="body1" className="mt-2" align="center" component="div">
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={5} className={HCClasses.textAlignRight}>Talisis <i className="ri-checkbox-circle-fill text-violet-300"></i></Grid>
                                                                        <Grid item xs={2}>-</Grid>
                                                                        <Grid item xs={5} className={HCClasses.textAlignLeft}>NEO
                                                                            {(() => {
                                                                                let result = <i className="ri-close-circle-fill text-error-300 ml-1"></i>;
                                                                                course.exists_in_neo = false;
                                                                                for (let neo_course of _data.neo_user_courses_data[0][course.company_id]) {
                                                                                    if (course.course_url_neo && course.course_url_neo.includes(neo_course.id)) {
                                                                                        course.exists_in_neo = true;
                                                                                        result = <i className="ri-checkbox-circle-fill text-violet-300 ml-1"></i>;
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                return result;
                                                                            })()}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Typography>
                                                                <Button variant="contained" color="default" size="small" className="mt-2" fullWidth onClick={async () => {
                                                                    course.level_id = "EC";
                                                                    await handleFixClassNeo(idx, { "person_id": _data.id, "first_name": _data.first_name, "last_name": _data.last_name, "emails": _data.emails, "matricula": _data.user_id, "pidm": _data.external_id }, course);
                                                                }}
                                                                    disabled={course.exists_in_neo || (loading && course.course_id === currentCourseIdToFix) ? true : false}>
                                                                    {loading && course.course_id === currentCourseIdToFix ? <CircularProgress className={HCClasses.loaderButton} /> : (course.exists_in_neo ? <React.Fragment><i className="ri-check-fill mr-2"></i>Conexión reparada</React.Fragment> : <React.Fragment><i className="ri-refresh-line mr-2"></i>Reparar conexión</React.Fragment>)}
                                                                </Button>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            ))
                                        }
                                    </Grid>
                                    :
                                    <Typography variant="body2" className="text-dark-black-200">Sin información para mostrar</Typography>
                            }
                            <Typography variant="h4" className="text-violet-300 mt-2">Cursos UNID</Typography>
                            <hr className={HCClasses.separator} />
                            {
                                hasCoursesOfProgramsUnid || hasCoursesEduconUnid ?
                                    <Grid container spacing={2}>
                                        {
                                            hasCoursesOfProgramsUnid && _data.programs.programs.map(program => program.learning_style === "asincronico" && (
                                                program.courses.map(course => course.company_id === "UNID" && (
                                                    <Grid key={`hc_grid_program_course_unid_${course.course_id}_item`} item xs={4}>
                                                        <Card>
                                                            <CardContent>
                                                                <Tooltip title={course.course_name} interactive>
                                                                    <Typography variant="body1" className={classes.titleCourseName}>{course.course_name}</Typography>
                                                                </Tooltip>
                                                                <Typography variant="body1" className="mt-2" align="center" component="div">
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={5} className={HCClasses.textAlignRight}>Talisis <i className="ri-checkbox-circle-fill text-violet-300"></i></Grid>
                                                                        <Grid item xs={2}>-</Grid>
                                                                        <Grid item xs={5} className={HCClasses.textAlignLeft}>NEO
                                                                            {(() => {
                                                                                let result = <i className="ri-close-circle-fill text-error-300 ml-1"></i>;
                                                                                course.exists_in_neo = false;
                                                                                for (let neo_course of _data.neo_user_courses_data[0][course.company_id]) {
                                                                                    if (course.courses_url_neo && course.courses_url_neo.includes(neo_course.id)) {
                                                                                        course.exists_in_neo = true;
                                                                                        result = <i className="ri-checkbox-circle-fill text-violet-300 ml-1"></i>;
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                return result;
                                                                            })()}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Typography>
                                                                <Button variant="contained" color="default" size="small" className="mt-2" fullWidth onClick={async () => {
                                                                    await handleFixClassNeo(idx, { "person_id": _data.id, "first_name": _data.first_name, "last_name": _data.last_name, "emails": _data.emails, "matricula": _data.user_id, "pidm": _data.external_id }, course);
                                                                }}
                                                                    disabled={course.exists_in_neo || (loading && course.course_id === currentCourseIdToFix) ? true : false}>
                                                                    {loading && course.course_id === currentCourseIdToFix ? <CircularProgress className={HCClasses.loaderButton} /> : (course.exists_in_neo ? <React.Fragment><i className="ri-check-fill mr-2"></i>Conexión reparada</React.Fragment> : <React.Fragment><i className="ri-refresh-line mr-2"></i>Reparar conexión</React.Fragment>)}
                                                                </Button>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            ))
                                        }
                                        {
                                            hasCoursesEduconUnid && _data.courses.educon.map(coursesByTenant => (
                                                coursesByTenant.courses.map(course => course.source_id === 1 && course.modality_id === 2 && course.company_id === "UNID" && (
                                                    <Grid key={`hc_grid_educon_course_unid_${course.course_id}_item`} item xs={4}>
                                                        <Card>
                                                            <CardContent>
                                                                <Tooltip title={course.name} interactive>
                                                                    <Typography variant="body1" className={classes.titleCourseName}>{course.name}</Typography>
                                                                </Tooltip>
                                                                <Typography variant="body1" className="mt-2" align="center" component="div">
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={5} className={HCClasses.textAlignRight}>Talisis <i className="ri-checkbox-circle-fill text-violet-300"></i></Grid>
                                                                        <Grid item xs={2}>-</Grid>
                                                                        <Grid item xs={5} className={HCClasses.textAlignLeft}>NEO
                                                                            {(() => {
                                                                                let result = <i className="ri-close-circle-fill text-error-300 ml-1"></i>;
                                                                                course.exists_in_neo = false;
                                                                                for (let neo_course of _data.neo_user_courses_data[0][course.company_id]) {
                                                                                    if (course.course_url_neo && course.course_url_neo.includes(neo_course.id)) {
                                                                                        course.exists_in_neo = true;
                                                                                        result = <i className="ri-checkbox-circle-fill text-violet-300 ml-1"></i>;
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                return result;
                                                                            })()}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Typography>
                                                                <Button variant="contained" color="default" size="small" className="mt-2" fullWidth onClick={async () => {
                                                                    course.level_id = "EC";
                                                                    await handleFixClassNeo(idx, { "person_id": _data.id, "first_name": _data.first_name, "last_name": _data.last_name, "emails": _data.emails, "matricula": _data.user_id, "pidm": _data.external_id }, course);
                                                                }}
                                                                    disabled={course.exists_in_neo || (loading && course.course_id === currentCourseIdToFix) ? true : false}>
                                                                    {loading && course.course_id === currentCourseIdToFix ? <CircularProgress className={HCClasses.loaderButton} /> : (course.exists_in_neo ? <React.Fragment><i className="ri-check-fill mr-2"></i>Conexión reparada</React.Fragment> : <React.Fragment><i className="ri-refresh-line mr-2"></i>Reparar conexión</React.Fragment>)}
                                                                </Button>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            ))
                                        }
                                    </Grid>
                                    :
                                    <Typography variant="body2" className="text-dark-black-200">Sin información para mostrar</Typography>
                            }
                        </React.Fragment>
                    )
                })
            }
            <Toast {...toastProps} onFinished={() => onErrorMessage()} />
        </Box>
    )
};

export default FixNeoAssignments;