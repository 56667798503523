import { combineReducers } from 'redux';
import userReducer from './userReducer';
import menuReducer from './menuReducer';
import healthCheckReducer from './healthCheckReducer';

export default combineReducers({
    userReducer,
    menuReducer,
    healthCheckReducer,
});
