import React from 'react';
import { useMsal } from "@azure/msal-react";
import { log } from '../../../design-system/utils/console';

// @material-ui
import Box from '@material-ui/core/Box';
import * as Session from '../Session/Session';

const Logout = ({ children, ...rest }) => {
    const { instance } = useMsal();

    const singleSignOut = async (e) => {
        log('Logout.jsx. cerrando sesión');
        e.preventDefault();

        const instanceAccount = instance.getActiveAccount();
        const logoutRequest = {
            account: instanceAccount,
            postLogoutRedirectUri: `${window.location.origin}/login`,
            onRedirectNavigate: () => {
                // ...
            }
        };
        Session.removeSessionCookie(true);
        Session.removeCookie(Session.TMP_LOGIN_START_COOKIE);
        await instance.logoutRedirect(logoutRequest);
    };

    return (
        <Box onClick={singleSignOut} {...rest}>
            {children}
        </Box>
    );
};

export default Logout;