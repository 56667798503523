import React from 'react';
import clsx from 'clsx';

// Material components
import { Box, Grid, Icon, Link, TextField, Typography } from '@material-ui/core';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';



const CourseSelection = ({courses, onChangeText, onDrag}) => {

    const onDragEnd = (result) => {
        onDrag(result.source.index, result.destination.index);
    };

    const handleChangeText = (index) => (event) => onChangeText(event, index);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable' isDropDisabled={ false }>
            {(provided) => (
                <Grid item xs={12} container spacing={2} className='mt-1'
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {
                        courses.map((field, index) => (
                            <Draggable key={`dragg-${index}-root`} draggableId={`dragg-${index}-root`} index={index} isDragDisabled={ false }>
                            {(provided) => (
                                <React.Fragment key={`dragg-${index}-root`}>
                                        <Grid item xs={12} sm={12} md={field.col}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <TextField
                                                label={`Curso ${index + 1}:`}
                                                placeholder='Pega el ID o URL del curso'
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth 
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: <Icon className='ri-links-line text-dark-black-200'/>,
                                                    endAdornment: <Icon className='ri-menu-line text-dark-black-100'/>,
                                                }}
                                                value={field.course_id || field.course_url}
                                                onChange={handleChangeText(index)}
                                                error={!!(field.course_id || field.course_url) && field.hasOwnProperty("isValid") && !field.isValid}
                                            />
                                            { !!(field.course_id || field.course_url) && field.hasOwnProperty("isValid") && 
                                                <Box display="flex" mt="8px">
                                                    <Typography variant="body2" className={clsx("semi-bold", "text-dark-black-200")}>URL:&nbsp;</Typography>
                                                    {
                                                        !field.isValid ? <Typography variant="body2" className={clsx("text-error-300")}>{field.message}</Typography>
                                                        : <Typography variant="body2">
                                                            <Link href="www.google.com.mx" className={clsx("text-blue-400")}>{field.url}</Link>
                                                        </Typography>
                                                    }
                                                </Box>
                                            }
                                        </Grid>
                                </React.Fragment>
                            )}
                            </Draggable>
                        ))
                    }
                    {provided.placeholder}
                </Grid>
            )}
            </Droppable>     
        </DragDropContext>   
    );
};

export default CourseSelection;