import palette from '../palette';

export default {
    ul:{
        '& .MuiPaginationItem-root':{
            height: 26,
            minWidth: 22
        },
        '& li:first-child, li:last-child':{
            '& .MuiPaginationItem-page':{
                backgroundColor: palette.colors.darkBlack[100],
                color: palette.white
            }
        },
        '& li:not(:first-child):not(:last-child)':{
            '& .MuiPaginationItem-page':{
                color: palette.colors.darkBlack[200]
            }
        }
    }
};