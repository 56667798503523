import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        boxShadow: '0px 4px 27px 0px rgba(0, 0, 0, 0.07)',
        borderRight: 0
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    listItem:{
        flexDirection: 'column',
        '&:hover':{
            borderRadius: 8,
        },
        '&.Mui-selected, &.Mui-selected:hover':{
            background: theme.palette.colors.violet[300],
            borderRadius: 8,
            padding: 8
        },
    },
    listItemIcon:{
        minWidth: 'auto'
    },
    list:{
        padding: '32px 8px'
    }
}));

export default function PermanentSidebar({menu = [], onClickItem}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{ paper: classes.drawerPaper }}
                anchor="left"
            >
                <div className={classes.toolbar} />
                <List className={classes.list}>
                    {menu.map((menuItem, index) => (
                        <ListItem button selected={menuItem.active} 
                            key={`list-item-${index}`} 
                            className={classes.listItem} 
                            onClick={onClickItem.bind(this,menuItem)}>
                                <ListItemIcon className={classes.listItemIcon}>
                                    <Icon className={clsx(`${menuItem.icon}-${menuItem.active ? 'fill text-white' : 'line text-dark-black-200'}`)} />
                                </ListItemIcon>
                                <ListItemText primary={menuItem.label} 
                                    primaryTypographyProps={{
                                    variant: 'caption', 
                                    className: clsx('variant-caption-sm', menuItem.active ? 'text-white' : 'text-dark-black-200' )}}/>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </div>
    );
}
