import palette from '../palette';

export default {
  root:{
    padding: 8,
    borderRadius: 8
  },
  colorSecondary: {
    boxShadow: 'unset',
    backgroundColor: palette.colors.darkBlack[200],
    color: palette.colors.gray[100],

    '&:hover': {
      backgroundColor: palette.colors.fillStroke[300]
    },

    '&.Mui-disabled':{
      backgroundColor: palette.colors.fillStroke[500],
      border: `1px solid ${palette.colors.fillStroke[300]}`,
      color: palette.colors.darkBlack[200],
    }
  },
  colorPrimary:{
    color: palette.colors.white,
    background: palette.colors.electricBlue[300],
    borderRadius: 8,
    padding: 8,
    '& .MuiIcon-root':{
      fontSize: '16px !important'
    },
    '&:hover':{
      backgroundColor: palette.colors.violet[400],
    },
  },
  // colorSecondary:{
  //   background: palette.colors.fillStroke[500],
  //   border: `0.5px solid ${palette.colors.violet[300]}`,
  //   borderRadius: 40,
  //   color: palette.colors.white,
  //   '&.border-button':{
  //     borderRadius: 8
  //   },
  //   '&:hover':{
  //     backgroundColor: palette.colors.violet[300],
  //   },
  // },
};
