import React from 'react';
import { Link, } from 'react-router-dom';

import SplitLogin from './SplitLogin/SplitLogin';
import { getLogoBySource } from '../../design-system/utils/getLogo';
import { userIsLoggedIn } from './Session/Session';
import { SOURCES } from '../../design-system/constants/sources';
import { ROOT_HOME } from '../../routes/paths';

// @material-ui/core
import { Typography, Box, IconButton, makeStyles } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import { WHITE } from '../../design-system/constants/color-images';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        width: '100%',
        margin: '0px auto',
        height: '100vh',
    },
    card: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 60,
        },
        backgroundColor: `${theme.palette.colors.fillStroke[400]} !important`,
        border: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        marginTop: 56,
        padding: '16px 80px !important',
    },
    cardHeader: {
        padding: "16px 20px 0 0"
    },
    cardContent: {
        padding: '16px 80px !important',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: '15px !important',
        }
    },
    headerActions: {
        width: '100%'
    },
    talisisLogo: {
        width: 120
    },
    closeIcon: {
        fontSize: '24px',
        color: theme.palette.colors.darkBlack[200]
    },
    closeIconButton: {
        padding: 0,
        float: 'right'
    },
}));

const Login = (props) => {
    const classes = useStyles();
    
    React.useEffect(() => {
        if (userIsLoggedIn()) {
            props.history.push(ROOT_HOME);
        }
    }, [props]);

    return (
        <Box>
            <Box className={classes.root}>
                <Card elevation={0} className={`${classes.card}`}>
                    <CardHeader className={classes.cardHeader}
                        action={
                            <IconButton className={classes.closeIconButton} component={Link} to={'/login'}>
                                <i className={clsx(classes.closeIcon, 'ri-close-line')} />
                            </IconButton>
                        }
                        classes={{ action: classes.headerActions }}
                    />
                    <CardContent className={classes.cardContent}>
                        <img src={getLogoBySource(SOURCES.TALISIS, WHITE)} className={classes.talisisLogo} alt="img-logo"/>
                        <Typography variant="body1" className="semi-bold mt-4" color="primary">Bienvenid@</Typography>
                        <Typography variant="body1" className={`semi-bold text-gray-100 mb-4`} align={'center'}>a Admin Talisis</Typography>
                        <SplitLogin {...props} />
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default Login;
