import React from 'react';

// Material components
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

// Shared components 
import FileDetail from '../../../design-system/components/Files/FileDetail/FileDetail';
import Select from '../../../design-system/components/Form/Select/Select';
import DrawerButtonContainer from '../../../design-system/components/Drawer/DrawerButtonContainer';
import dateFormat from '../../../design-system/utils/dateFormat';
import { SIMPLE_DATE_FORMAT, DATE_FORMAT_SHORT } from '../../../design-system/constants/date-formats';
import getImageFromAssets from '../../../design-system/utils/getImageFromAssets';

import { RecordDocumentService } from '@sdk-point/talisis';
import { Divider } from '@material-ui/core';

const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


const ShowRecord = ({userId, fullName = ''}) => {
    const [filterByValue, setFilterByValue] = React.useState('all');
    const [showValue, setShowValue] = React.useState('all');
    const [filtersList, setFiltersList] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [skeletonLoading, setSkeletonLoading] = React.useState(false);
    const [student, setStudent] = React.useState({birth_date: null})
    const [documents, setDocuments] = React.useState([]);
    const [showValueDetail, setShowValueDetail] = React.useState({});

    const filters = filterByValue !==  'all' 
        ? [{id: 'all', label:'Todos'}, ...filtersList[filterByValue] || {}] 
        : [{id: 'all', label:'Todos'}];

    const getStudent = async () => {
        setLoading(true);
        try {
            const responseFilters = await RecordDocumentServiceSDK.getStudentRecordTypes(userId);
            const response = await getDocuments('all');
            setFiltersList(responseFilters)
            setStudent(response.student);
            setLoading(false)

        } catch (error) {
            setLoading(false)   
        }
    };

    const getDocuments = async (value) => {
        let response;
        setShowValue(value);
        let params = {};

        if(value !== 'all'){
            params = {
                requestId: filterByValue === 'requests' ? value : '',
                programId: filterByValue === 'programs' ? value : ''
            }
        }

        try {
            response = await RecordDocumentServiceSDK.getStudentRecord(userId, params);
            setDocuments(response.documents);
            setLoading(false);

        } catch (error) {
            console.log('errors', error);
        }

        return response;
    }
    
    const handleChange = (event) => {
        const {name, value} = event.target;
        setLoading(true);
        if(name === 'filterBy'){
            setFilterByValue(value);
            getDocuments('all');
        }else{
            const detail = filters.find(it => it.id === value);
            setShowValueDetail(detail);
            getDocuments(value);
        }
    };

    const hanldeClickButton = (statusOpen) => {
        setStudent({full_name: ''});
        if(statusOpen){
            setDocuments([]);
            getStudent();
        }
    };

    const loadingState = [...Array(3).keys()].map((index) => (
        <Card key={`school-record-loading-${index}`} elevation={2}>
            <CardContent>
                <Box display='inline-flex' alignItems='center' gridGap={8} width={1}>
                    <Box height={40} width={40} className="card-skeleton-light"></Box>
                    <Box display='flex' flex={1} flexDirection='column' gridGap={8}>
                        <Box height={16} width={200} className="card-skeleton-light"></Box>
                        <Box height={14} width={100} className="card-skeleton-light"></Box>
                    </Box>
                    <Box height={40} width={140} className="card-skeleton-light"></Box>
                </Box>
            </CardContent>
        </Card>
    ))

    return (
        <DrawerButtonContainer
            size='small'
            className='show-button'
            textBttn='Abrir'
            title='Expediente del Alumno'
            fullWidth
            onClickButton={(event) => hanldeClickButton(event)}
            loading={loading}
        >
            <Box p={3} pt={1}>

            
            <Grid container spacing={3}>            
                <Grid item xs={12} container spacing={4}>
                    <Grid item xs={12}>
                        <Box display='flex' gridGap={16}>
                            <Box width='60%'>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{ shrink: true, className: 'font-size-12' }} 
                                    variant='outlined'
                                    label="Nombre"
                                    name='province'
                                    value={fullName.toUpperCase()}
                                    className='input-text-plain'
                                    InputProps={{ readOnly: true}}
                                    inputProps={{ className: 'text-dark-black-500 font-size-14'}}
                                />
                            </Box>

                            <TextField
                                InputLabelProps={{ shrink: true, className: 'font-size-12' }} 
                                variant='outlined'
                                label="Matricula"
                                name='province'
                                value={student?.user_id}
                                className='input-text-plain'
                                InputProps={{ readOnly: true}}
                                inputProps={{ className: 'text-dark-black-500 font-size-14'}}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex' gridGap={16}>
                            <TextField
                                style={{minWidth:180}}
                                InputLabelProps={{ shrink: true, className: 'font-size-12' }} 
                                variant='outlined'
                                label="CURP"
                                name='province'
                                value={student?.curp || '-'}
                                className='input-text-plain'
                                InputProps={{ readOnly: true}}
                                inputProps={{ className: 'text-dark-black-500 font-size-14'}}
                            />
                            <TextField
                                InputLabelProps={{ shrink: true, className: 'font-size-12' }} 
                                variant='outlined'
                                    
                                label="Fecha de Nacimiento"
                                name='province'
                                value={dateFormat(student.birth_date, DATE_FORMAT_SHORT)}
                                className='input-text-plain'
                                InputProps={{ readOnly: true}}
                                inputProps={{ className: 'text-dark-black-500 font-size-14'}}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Select
                        label="Filtrar por"
                        onChange={handleChange}
                        value={filterByValue}
                        name="filterBy"
                        items={[
                            {id: 'all', label:'Todos'},
                            {id: 'programs', label:'Programas'},
                            {id: 'requests', label:'Trámite'},
                        ]}
                    />
                </Grid>
                {
                    filterByValue !== 'all' &&  
                    <Grid item xs={12}>
                            <Select
                                label="Mostrar"
                                onChange={handleChange}
                                value={showValue}
                                name="show"
                                items={filters}
                            />
                    </Grid>
                }
                {
                    filterByValue === 'programs' && showValue !== 'all' &&
                    <Grid item xs={12}>
                        <Card elevation={2}>
                            <CardContent>
                                <Box display='flex' gridGap={16} py={2}>
                                    <Box width='25%'>
                                        <TextField
                                            fullWidth
                                            InputLabelProps={{ shrink: true, className: 'font-size-12' }} 
                                            variant='outlined'
                                            label="Nivel"
                                            name='level'
                                            value={showValueDetail.level}
                                            className='input-text-plain'
                                            InputProps={{ readOnly: true}}
                                            inputProps={{ className: 'text-dark-black-500 font-size-14'}}
                                        />
                                    </Box>

                                    <TextField
                                        fullWidth
                                        InputLabelProps={{ shrink: true, className: 'font-size-12' }} 
                                        variant='outlined'
                                        label="Nombre de estudios"
                                        name='program-name'
                                        value={showValueDetail?.name}
                                        className='input-text-plain'
                                        InputProps={{ readOnly: true}}
                                        inputProps={{ className: 'text-dark-black-500 font-size-14'}}
                                    />
                                </Box>
                                <Box display='flex' gridGap={16} pt={2}>
                                    <Box width='25%'>
                                        <TextField
                                            InputLabelProps={{ shrink: true, className: 'font-size-12' }} 
                                            variant='outlined'
                                            label="Clave del nivel"
                                            name='clave'
                                            value={showValueDetail?.third_party_id}
                                            className='input-text-plain'
                                            InputProps={{ readOnly: true}}
                                            inputProps={{ className: 'text-dark-black-500 font-size-14'}}
                                        />
                                    </Box>
                                    <TextField
                                        fullWidth
                                        InputLabelProps={{ shrink: true, className: 'font-size-12' }} 
                                        variant='outlined'
                                        label="Marca"
                                        name='level'
                                        value={showValueDetail.company}
                                        className='input-text-plain'
                                        InputProps={{ readOnly: true}}
                                        inputProps={{ className: 'text-dark-black-500 font-size-14'}}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Divider className='bg-fill-stroke-50'/>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                    <Box display='flex' flexDirection='column' gridGap={8}>

                        {/* {skeletonLoading && loadingState} */}
                        {!loading &&
                            documents.map((docObj, index) =>                        
                                <Card key={`school-record-doc-${index}`} elevation={2}>
                                    <CardContent>
                                        <Box display='inline-flex' width={1} alignItems='center'>
                                            <FileDetail 
                                                title={<span className='semi-bold'>{docObj.name}</span>}
                                                filePath={docObj.path}
                                                dueDateText="Fecha de carga:"
                                                dueDate={<Typography component="span" variant='body2' className='text-dark-balck-500'>{dateFormat(docObj.last_updated, SIMPLE_DATE_FORMAT) || 'No disponible'}</Typography>}
                                                active={false}
                                                downloadableFile={false}
                                                gridGap={8}
                                            />
                                            <Box ml='auto'>
                                                <Button 
                                                    disabled={!docObj.path}
                                                    fullWidth
                                                    variant='contained' 
                                                    startIcon={<Icon className='ri-download-line' />} 
                                                    disableRipple 
                                                    href={docObj.path}>
                                                        Descargar
                                                </Button>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            )
                        }
                        {
                            !loading && !documents.length &&

                            <Box px={3} py={4} textAlign='center'>
                                <img src={getImageFromAssets('themeSearching')} />
                                <Box pt={3} display='flex' flexDirection='column' gridGap={8}>
                                    <Typography variant='body1' className='text-dark-black-500 semi-bold'>Nada por aquí todavia</Typography>
                                    <Typography variant='body2' className='text-dark-black-500'>El alumno seleccionado aún no cuenta con documentos en su expediente.</Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
            </Box>
        </DrawerButtonContainer>
    );
};

export default ShowRecord;