import React from 'react';
import PropTypes from 'prop-types'


import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Icon, TextField} from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import "moment/locale/es";

const Picker =  ({format, variant, name, onChangeDate, InputProps, ...rest}) => {
    const rootRef = React.useRef();

    const handleDateChange = (date) => {
        onChangeDate({name, value: date});
    };

    return (
        <div>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Box ref={rootRef} sx={{width: "fit-content"}}>
                    <DatePicker
                        disableToolbar
                        variant="inline"
                        format={format}
                        margin="normal"
                        id="date-picker-inline"
                        onChange={handleDateChange}
                        PopoverProps={{
                            anchorEl: () => rootRef.current
                        }}
                        TextFieldComponent={(params) => <TextField {...params} variant={variant}
                            InputProps={{
                                startAdornment: <Icon className='ri-calendar-2-line text-dark-black-200'/>,
                                ...InputProps
                            }}
                            />}
                        placeholder='DD-MM-YYYY'
                        {...rest}
                        />
                </Box>
            </MuiPickersUtilsProvider>
        </div>
    );
};

Picker.defaultProps = {
    format: "DD MMMM YYYY",
    variant: 'outlined',
    placeholder: "día/mes/año",
    onChangeDate: () => {}
};

Picker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    label: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    onChangeDate: PropTypes.func
};

export default Picker;