import palette from '../palette';

export default {
    root: {
        backgroundColor: 'white',
        'label + &': {
            marginTop: 8,
            '& fieldset':{
                top: 0,
                '& legend':{
                    position: 'absolute'
                }
            }
        },
        '&.Mui-disabled':{
            color: '#404150 !important'
        },
        '&.MuiOutlinedInput-multiline': {
            padding: '0px !important'
        },
    },
    input: {
        color: palette.colors.darkBlack[200],
        padding: '11px 16px !important',
        caretColor: palette.colors.violet[300],
        '&::placeholder': {
            color: palette.colors.darkBlack[200], // Cambia el color del marcador de posición aquí
        },
        '&:focus::placeholder': {
            padding: '0 0.5rem', // Agrega espacio horizontal aquí
        },
    },
};