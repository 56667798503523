const findDeep = (items, prop, value, nestedKey) => {
    for(let item of items){
        if(item[prop] === value){
            return item;
        }else if(item[nestedKey] && findDeep(item[nestedKey], prop, value, nestedKey)){
            return findDeep(item[nestedKey], prop, value, nestedKey);
        }
    }

    return null;
}

export default findDeep;