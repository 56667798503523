import React from "react";
import {connect} from "react-redux";
import {useSelector} from "react-redux";
import {SET_USER_DATA} from "../../../redux/types/healthCheckTypes";

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    Grid,
    makeStyles,
    Typography,
    TextField,
    InputAdornment,
    CircularProgress,
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import {HCCSSClasses} from "./HealthCheckHelpers/CSSClasses";
import startSearchImage from "../HealthCheck/HealthCheckHelpers/placeholders/start_search.svg";
import notFoundImage from "../../../design-system/assets/images/theme/searching.svg";

import {ACTIONS} from "./HealthCheckHelpers/Actions";
import {ACC_UNIF_REQUEST_STATUS} from "./HealthCheckHelpers/Status";
import BasicData from "./HealthCheckSections/BasicData";
import Emails from "./HealthCheckSections/Emails";
import Roles from "./HealthCheckSections/Roles";
import CoursesInProgress from "./HealthCheckSections/CoursesInProgress";
import UserAlliesInfo from "./HealthCheckSections/UserAlliesInfo";
import SimpleTabs from "../../../design-system/components/Tabs/Tabs";
import AccountUnification from "./HealthCheckSections/Tabs/AccountUnification";
import FixNeoAssignments from "./HealthCheckSections/Tabs/FixNeoAssignments";
import {log} from '../../../design-system/utils/console';

import {HealthCheckService} from "@sdk-point/talisis";
import {isEmpty, isNil} from "ramda";

const HealthCheckServiceSDK = new HealthCheckService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const useStyles = makeStyles((theme) => (HCCSSClasses(theme)));

const accordionsData = [
    {id: "basic_data", title: "Información básica del usuario", component: (props) => <BasicData {...props} />},
    {id: "emails", title: "Correos electrónicos", component: (props) => <Emails key={props.key_id} {...props} />},
    {id: "roles", title: "Roles", component: (props) => <Roles key={props.keyId} {...props} />},
    {
        id: "courses_in_progress",
        title: "Información de cursos y programas en progreso",
        component: (props) => <CoursesInProgress {...props} />
    },
    {
        id: "user_allies_info",
        title: "Información de acceso a alianzas",
        component: (props) => <UserAlliesInfo {...props} />
    }
];

const accordionRender = ({id, component}, idxData, data, props, classes, onClick, loading) => {
    switch (id) {
        case "basic_data":
            return component({data});
        case "emails":
            return (
                <Grid key={`hc_grid_${id}_container`} container spacing={3} className="mt-2">
                    {data.emails.map((emailData) => {
                        return component({
                            key_id: `hc_fragment_email_${emailData.id}`,
                            data: emailData,
                            bannerId: data.user_id,
                            HCClasses: classes,
                            HCProps: {onClick, loading}
                        });
                    })}
                </Grid>
            );
        case "roles":
            return (
                <Grid key={`hc_grid_${id}_container`} container spacing={3} className="mt-2">
                    {data.roles.map((roleData) => {
                        return component({
                            keyId: `hc_grid_role_${roleData.id}_item`,
                            data: roleData,
                            HCClasses: classes,
                        })
                    })}
                </Grid>
            );
        case "courses_in_progress":
            return component({data: {programs: data?.programs?.programs, courses: data?.courses},});
        case "user_allies_info":
            return component({
                data: {neo: data.neo_user_data, allies: data.allys_connect},
                idxData,
                HCProps: {data: props.userData, setData: props.setUserData},
                HCClasses: classes
            });
        default:
            break;
    }
};

const HealthCheck = (props) => {
        const SEARCH_EMAIL_LABEL = "Haz una búsqueda por correo para comenzar.";
        const SEARCH_ERROR_LABEL = "Usuario no encontrado.";
        const INPUT_VALID_EMAIL_LABEL = "Verifica el correo que haz escrito o genera una nueva búsqueda.";
        const {user: sessionUser} = useSelector((state) => state.userReducer);
        const [searchLabel, setSearchLabel] = React.useState(SEARCH_EMAIL_LABEL);
        const [loading, setLoading] = React.useState(false);
        const [email, setEmail] = React.useState("");
        const [notFoundData, setNotFoundData] = React.useState(false);
        const [diagnostic, setDiagnostic] = React.useState({});
        const [activeTab, setActiveTab] = React.useState(0);
        const [unificationStatus, setUnificationStatus] = React.useState(ACC_UNIF_REQUEST_STATUS.NOT_APPLY);
        const classes = useStyles();
        const [loadingModal, setLoadingModal] = React.useState(false);

        const handleChangeEmail = (e) => {
            setEmail(e.target.value);
        };

        const restoreData = () => {
            props.setUserData([]);
            setDiagnostic([]);
            setNotFoundData(false);
            setSearchLabel(SEARCH_EMAIL_LABEL);
        };

        const handleSearchEmail = async (loading = true) => {
            if (loading) {
                restoreData();
                setLoading(true);
            }

            let finalEmail = email.trim();
            if (finalEmail && /\S+@\S+\.\S+/.test(finalEmail)) {
                // Datos del usuario:
                const response = await HealthCheckServiceSDK.invoke(ACTIONS.TALISIS_USER_INFO, {email: finalEmail});
                await process(finalEmail, response);
            } else {
                setNotFoundData(true);
                setSearchLabel(SEARCH_ERROR_LABEL);
            }

            setLoading(false);
        };

        const process = async (email, response) => {
            if (response && response.data && response.data.length > 0) {
                log({response});
                props.setUserData(response.data);
                setDiagnostic(response.logs);
                await _processUnification(email, response.data);
            } else {
                setNotFoundData(true);
                setSearchLabel(SEARCH_ERROR_LABEL);
            }
        };

        const _processUnification = async (email, data) => {
            let accountUnificationRequestStatus = null;
            for (let _data of data) {
                if (_data.acc_unif_req_status) {
                    accountUnificationRequestStatus = _data.acc_unif_req_status;
                    break;
                }
            }

            if (accountUnificationRequestStatus) {
                if (accountUnificationRequestStatus === "Pending") {
                    setUnificationStatus(ACC_UNIF_REQUEST_STATUS.PENDING);
                } else if (accountUnificationRequestStatus === "In Process") {
                    setUnificationStatus(ACC_UNIF_REQUEST_STATUS.IN_PROCESS);
                } else if (accountUnificationRequestStatus === "Unified") {
                    setUnificationStatus(ACC_UNIF_REQUEST_STATUS.UNIFIED);
                }
            } else {
                if (data.length === 1) {
                    setUnificationStatus(ACC_UNIF_REQUEST_STATUS.NOT_APPLY);
                } else {
                    let person_ids_to_unification = [];
                    for (let _data of data) {
                        person_ids_to_unification.push(_data.id);
                    }

                    person_ids_to_unification = person_ids_to_unification.sort((a, b) => (a - b));
                    await HealthCheckServiceSDK.invoke(ACTIONS.ACCOUNT_UNIFICATION_REQUEST, {
                        person_id_request: sessionUser.person_id,
                        person_ids_to_unification,
                        email_to_unification: email
                    });
                    setUnificationStatus(ACC_UNIF_REQUEST_STATUS.PENDING);
                }
            }
        };

        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                handleSearchEmail();
            }
        };

        const handleUpdatePrincipalEmail = async (personIndex, personId, emailId, newEmail, emailCode, bannerId) => {
            let newData = [...props.userData];
            setLoadingModal(true);
            const responseHC = await HealthCheckServiceSDK.invoke(ACTIONS.SET_USER_PRINCIPAL_EMAIL, {
                "person_id": personId,
                "email_id": emailId,
                "email": newEmail,
                "email_code": emailCode,
                "matricula": bannerId
            });
            if (!isNil(responseHC) || !isEmpty(responseHC)) {
                newData[0].emails = responseHC
            }
            props.setUserData(newData);
            setLoadingModal(false);

        }

        return (
            <React.Fragment>
                <Box className={classes.box}>
                    <Typography variant="h3">Health check</Typography>
                    <Typography variant="body2" className={classes.note}>Identifica la salud de las cuentas de los
                        usuarios.</Typography>
                    <Box display="inline-flex" width={"50%"}>
                        <TextField variant="outlined"
                                   placeholder="Buscar por correo"
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <i className="ri-search-line text-dark-black-200"></i>
                                           </InputAdornment>
                                       ),
                                   }}
                                   value={email}
                                   onChange={handleChangeEmail}
                                   onKeyDown={handleKeyDown}
                                   fullWidth
                        />
                        <Button variant="contained" color="primary" className="ml-2" onClick={handleSearchEmail}
                                size="medium">Buscar</Button>
                    </Box>
                </Box>
                {
                    props.userData.length > 0 ?
                        <React.Fragment>
                            <Box className={`mt-3 ${classes.box}`}>
                                <Typography variant="h4" style={{width: "100%"}}>
                                    Mensajes de estatus de cuenta
                                    <Button variant="contained" color="default" size="small" className="float-right"
                                            disabled={true}>Consultar glosario</Button>
                                </Typography>
                                <Typography variant="body2" className={classes.note}>Visualización de mensajes con los
                                    detalles
                                    encontrados en la cuenta previamente introducida.</Typography>
                                {
                                    diagnostic && Object.keys(diagnostic).length > 0 && Object.keys(diagnostic).map((key, idx) => {
                                        const diagnosticByPersonId = diagnostic[key];
                                        return (
                                            <Grid container key={`hc_grid_message_container_${idx}`} className="mt-2"
                                                  spacing={2}>
                                                <Grid item xs={12} className={classes.valign_center}>
                                                    <Typography variant="body2"
                                                                className="bold">{key === "0" ? "Resumen" : key}:</Typography>
                                                </Grid>
                                                {
                                                    diagnosticByPersonId.map((diagnosticData, idx) => (
                                                        <Grid key={`hc_grid_message_item_${idx}`} item xs={12}
                                                              className={classes.valign_center}>
                                                            {diagnosticData.ok === true && diagnosticData.severity === "success" &&
                                                                <i className="ri-checkbox-circle-fill text-success-400"></i>}
                                                            {diagnosticData.ok === true && diagnosticData.severity === "info" &&
                                                                <i className="ri-information-fill text-warning-400"></i>}
                                                            {diagnosticData.ok === false && diagnosticData.severity === "error" &&
                                                                <i className="ri-alert-fill text-error-300"></i>}
                                                            {diagnosticData.ok === false && diagnosticData.severity === "warning" &&
                                                                <i className="ri-information-fill text-warning-400"></i>}
                                                            <Typography variant="body2"
                                                                        className={`ml-2 ${diagnosticData.ok ? "" : "bold"}`}>{diagnosticData.text}</Typography>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                            </Box>
                            {
                                props.userData.map((data, id) => (
                                    <React.Fragment key={`hc_fragment_person_data_id_${data.id}`}>
                                        <Box className={`mt-3 ${classes.box} ${classes.accordionHeader}`}>
                                            <Typography variant="h4" className="text-violet-300"
                                                        style={{width: "100%"}}>{data.id}</Typography>
                                        </Box>
                                        <Box className="mb-3">
                                            {
                                                accordionsData.map((accordionData, idx) => (
                                                    <Accordion key={`hc_accordion_${idx}`} className={classes.accordion}>
                                                        <AccordionSummary expandIcon={<ExpandMore/>}
                                                                          className={`${classes.accordionSummary} ${(accordionsData.length - 1 === idx) && classes.accordionSummaryLast}`}>
                                                            <Typography variant="h4">{accordionData.title}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails className={classes.accordionDetails}>
                                                            {accordionRender(accordionData, id, data, props, classes, handleUpdatePrincipalEmail, loadingModal)}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))
                                            }
                                        </Box>
                                    </React.Fragment>
                                ))
                            }
                            <SimpleTabs onChange={(tabId) => {
                                setActiveTab(tabId)
                            }} activeTab={activeTab} tabs={["Unificación de cuentas", "Enrolamiento a clases NEO",]}>
                                {activeTab === 0 && <AccountUnification status={unificationStatus} HCClasses={classes}/>}
                                {activeTab === 1 &&
                                    <FixNeoAssignments data={props.userData} setData={props.setUserData} HCClasses={classes}
                                                       HealthCheckService={HealthCheckServiceSDK}/>}
                            </SimpleTabs>
                        </React.Fragment>
                        :
                        loading ?
                            <Box className={`mt-3 ${classes.boxLoader}`}><CircularProgress
                                className={classes.loader}/></Box>
                            :
                            notFoundData ?
                                <Box className={`mt-3 ${classes.boxSearch}`}>
                                    <img src={notFoundImage} alt={searchLabel}/>
                                    <Typography variant="body2"
                                                className="text-dark-black-200 semi-bold mt-4">{searchLabel}</Typography>
                                    <Typography variant="body2"
                                                className="text-dark-black-200 mt-2">{INPUT_VALID_EMAIL_LABEL}</Typography>
                                </Box>
                                :
                                <Box className={`mt-3 ${classes.boxSearch}`}>
                                    <img src={startSearchImage} alt={searchLabel}/>
                                    <Typography variant="body2"
                                                className="text-dark-black-200 semi-bold mt-4">{searchLabel}</Typography>
                                </Box>
                }
            </React.Fragment>
        );
    }
;

const mapStateToProps = ({healthCheckReducer}) => ({
    ...healthCheckReducer
});

const mapDispatchToProps = (dispatch) => ({
    setUserData: (data) => dispatch({type: SET_USER_DATA, payload: data}),
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthCheck);