import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import PermanentSidebar from './components/PermanentSidebar';
import ExpandableSidebar from './components/ExpandableSidebar';

import findDeep from '../../design-system/utils/findDeep';

import pages from '../../routes/pages'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

function findNodeById(data, targetId) {
    for (const item of data) {
      if (item.id === targetId) {
        return item;
      } else if (item.children && Array.isArray(item.children)) {
        const foundInChildren = findNodeById(item.children, targetId);
        if (foundInChildren) {
          return item; // Devuelve el nodo padre que envuelve al nodo encontrado en children
        }
      }
    }
    return null; // Devuelve null si no se encuentra el ID
  }

export default function Sidebar() {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const currentPath = location.pathname === '/' ? '/reportes' : location.pathname;

    const [subMenu, setSubMenu] = React.useState([]);
    const [menu, setMenu] = React.useState();

    React.useEffect(() => {
        // obtengo los items activos
        const activeNode = findDeep(pages,'path', currentPath, 'children');
        if (activeNode) {
          const parentNode = findNodeById(pages, activeNode.id);

          const _subMenu = !parentNode.children ? [] : parentNode.children.map(it => {
              it.active = it.id === activeNode.id;
              return it;
          })

          const _menu = pages.map(({ component, ...rest }) => {
              rest.active = parentNode.id === rest.id;
              return rest;
          });
          
          setSubMenu(_subMenu);
          setMenu(_menu);
        }
    }, [currentPath]);


    const hanldeClickMenuItem = (menuItem) => {
        const menuItemSelected = pages.find(m => m.id === menuItem.id);
        const goTo = menuItemSelected.children?.[0]?.path ?? menuItemSelected.path;

        history.push(goTo);
    };

    const hanldeClickSubMenuItem = (menuItem) => {
        history.push(menuItem.path);
    };

    return (
        <div className={classes.root}>
        <PermanentSidebar menu={menu} onClickItem={hanldeClickMenuItem}/>
        <ExpandableSidebar menu={subMenu} onClickItem={hanldeClickSubMenuItem}/>
        </div>
    );
}
