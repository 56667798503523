import React from 'react';
import ReactDOM from 'react-dom';

// @redux
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reducers from './redux/reducers';
import { thunk } from 'redux-thunk';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from './config/authConfig';
import App from './App';

import './index.css';
import 'remixicon/fonts/remixicon.css';

import '@sdk-point/talisis/lib/core/interceptors/axios';

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    :
    compose;
const enhancer = composeEnhancers(
    applyMiddleware(thunk),
);
const store = createStore(
    reducers,
    enhancer
);

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <App />
        </Provider>
    </MsalProvider>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
