import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { esES } from '@material-ui/core/locale';
import palette from './palette';
import overrides from './overrides';
import typography from './typography';
import styles from './styles';


const font =  "'Inter', sans-serif";


export default createMuiTheme({
    palette,
    overrides,
    typography: {
    fontFamily: font,
    ...typography
    },
    mixins: {
        // toolbar:{
        //   minHeight: 65,
        //   '@media (min-width:0px) and (orientation: landscape)': {
        //     minHeight: 65
        //   },
        //   '@media (min-width:600px)': {
        //     minHeight: 80
        //   },
        // }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
      maxWidth:{
        desktop: 1224,
        laptop: 1024,
        tablet: 768,
      }
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100
    },
    styles
  }, esES);