import React from 'react';
import clsx from 'clsx';

// Material components
import { Box, Button, Dialog, DialogTitle, Icon, IconButton, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        background: `${theme.palette.white} !important`,
        width: "648px",
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: "end",
        padding: "16px 16px 8px 16px"
    },
    bodyDialogContainer: {
        padding: "0 40px 32px 40px",
    },
    textDisplayCreatePlaylist: {
        color: `${theme.palette.colors.darkBlack[200]} !important`,
        textAlign: "center",
    }
}));

const ModalToConfirm = ({openModal, onCloseModal, modalProps, onClickConfirmButton, onClickCancelButton}) => {
    const classes = useStyles();

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={openModal} classes={{ paper: classes.dialogContainer }} keepMounted>
            <DialogTitle id="simple-dialog-title" disableTypography className={classes.dialogTitle}>
                <IconButton aria-label="close" className='p-0'
                    onClick={onCloseModal}
                >
                    <Icon className='ri-close-line text-fill-stroke-100' style={{ fontSize: 24 }} />
                </IconButton>
            </DialogTitle>
            <Box display="flex" flexDirection="column" className={classes.bodyDialogContainer} gridGap="32px">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gridGap="16px">
                    <Icon className={modalProps.iconClassname} style={{ fontSize: 32 }} />
                    <Typography variant='h4' className={clsx(classes.textDisplayCreatePlaylist)}>{modalProps.message}</Typography>
                </Box>
                <Box display="flex" justifyContent="center" gridGap={16} flexDirection="row">
                    {
                        !!onClickCancelButton && 
                        <Button variant='contained'
                            onClick={onClickCancelButton}
                        >Cancelar</Button>
                    }
                    <Button variant='contained' color='primary'
                        onClick={onClickConfirmButton}
                    >Aceptar</Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default ModalToConfirm;