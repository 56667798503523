import React from "react";
import { Button, Checkbox, Grid, TextField, Typography, CircularProgress, } from "@material-ui/core";

import { HealthCheckService } from "@sdk-point/talisis";
import { ACTIONS } from "../HealthCheckHelpers/Actions";
import Toast from '../../../../design-system/components/Toast/Toast';
const HealthCheckServiceSDK = new HealthCheckService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const UserNeoInfo = (props) => {
    const { data, idxData, HCProps, HCClasses, } = props;
    const roles = ["administrator", "assistant", "mentor", "monitor", "student", "teacher"];
    const rolesEsLang = { "administrator": "Administrador", "assistant": "Asistente", "mentor": "Mentor", "monitor": "Monitor", "student": "Estudiante", "teacher": "Maestro" };
    
    const [loading, setLoading] = React.useState(false);
    const defaultToastProps = { open: false, severity: "success", message: "" };
    const [toastProps, setToastProps] = React.useState(defaultToastProps);
    const [onUserNeoIdArchived, setOnUserNeoIdArchived] = React.useState(null);

    const handleUserNeoArchived = async (idx, archived, neo_user_id, company_id) => {
        setLoading(true);
        setOnUserNeoIdArchived(neo_user_id);

        try {
            await HealthCheckServiceSDK.invoke(ACTIONS.ARCHIVE_NEO_USER, { archived, neo_user_id, company_id })
                .then(response => {
                    let action = "";
                    if (response.archived === 1) {
                        action = "archivado";
                    }
                    else if (response.reactivated === 1) {
                        action = "reactivado";
                    }

                    if (response.reactivated === 1 || response.archived === 1) {
                        const newData = [...HCProps.data];
                        newData[idxData].neo_user_data[0][company_id].map((data, idx_) => {
                            if (idx === idx_) {
                                if (response.archived === 1) {
                                    data.archived = true;
                                }
                                else if (response.reactivated === 1) {
                                    data.archived = false;
                                }
                            }
                            
                            return data;
                        });
                        HCProps.setData(newData);
                        setToastProps({ open: true, severity: "success", message: `Se ha ${action} el usuario en NEO correctamente.`, });
                    }
                    else if (response.reactivated === 0 || response.archived === 0) {
                        setToastProps({ open: true, severity: "error", message: `No pudo ser ${action} el usuario en NEO: ${JSON.stringify(response)}`, });
                    }
                })
                .catch(error => {
                    setToastProps({ open: true, severity: "error", message: `Ha ocurrido un error: ${error}.`, });
                });
        }
        catch (error) {
            setToastProps({ open: true, severity: "error", message: `Ha ocurrido un error: ${error}.`, });
        }

        setLoading(false);
        setOnUserNeoIdArchived(null);
    };

    const onErrorMessage = () => {
        setToastProps(defaultToastProps);
        setLoading(false);
    };

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} className="mt-1 mb-2">
                    <Typography component="span" variant="h3" className="text-dark-black-200"><b>NEO:</b></Typography>
                </Grid>
                {
                    data.neo.map(userDataByTenant => {
                        const tenants = Object.keys(userDataByTenant);
                        if (tenants && tenants.length > 0) {
                            return (
                                tenants.map(tenant => (
                                    <React.Fragment key={`hc_fragment_neo_user_tenant_${tenant}`}>
                                        <Grid item xs={12} className={`mt-1 ${userDataByTenant[tenant] && userDataByTenant[tenant].length > 0 ? "mb-4" : ""}`}>
                                            <Typography variant="h4" color="primary"><b>{tenant}</b></Typography>
                                        </Grid>
                                        {
                                            userDataByTenant[tenant] && userDataByTenant[tenant].length > 0 ?
                                                userDataByTenant[tenant].map((userData, idx) => (
                                                    <React.Fragment key={`hc_fragment_neo_user_data_${userData.id}`}>
                                                        {
                                                            idx !== 0 &&
                                                            <Grid item xs={12} className="mb-4">
                                                                <hr className={HCClasses.separator} />
                                                            </Grid>
                                                        }
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="ID"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    className: "bg-gray-200",
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant="outlined"
                                                                value={userData.id || ""}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                label="Correo electrónico"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    className: "bg-gray-200",
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant="outlined"
                                                                value={userData.email || ""}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                label="User ID"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    className: "bg-gray-200",
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant="outlined"
                                                                value={userData.userid || ""}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="¿Archivado?"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    className: "bg-gray-200",
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                variant="outlined"
                                                                value={userData.archived ? "Sí" : "No"}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Grid container spacing={1}>
                                                                {roles.filter(role => userData[role]).map((role, k) => (
                                                                    <Grid key={`hc_grid_user_neo_info_${userData.id}_role_${role}_item`} item xs={k === 0 ? 3 : 2}>
                                                                        {k === 0 && <Typography component="span" variant="subtitle2" color="textSecondary" className="mr-1"><b>Roles:</b></Typography>}
                                                                        <Typography component="span" variant="body2">
                                                                            <Checkbox checked={userData[role] ? true : false} readOnly color="primary" />
                                                                            {rolesEsLang[role]}
                                                                        </Typography>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Button
                                                                variant="contained"
                                                                color="default"
                                                                size="small"
                                                                className="mt-1"
                                                                fullWidth
                                                                onClick={async () => {
                                                                    await handleUserNeoArchived(idx, userData.archived, userData.id, tenant);
                                                                }}
                                                                disabled={loading && userData.id === onUserNeoIdArchived ? true : false}>
                                                                    {(loading && userData.id === onUserNeoIdArchived) ? <CircularProgress className={HCClasses.loaderButton} /> : userData.archived ? "Desarchivar" : "Archivar"}
                                                            </Button>
                                                        </Grid>
                                                    </React.Fragment>
                                                ))
                                                :
                                                <Grid item xs={12}>
                                                    <Typography variant="body2" className="text-dark-black-200">No existe el usuario.</Typography>
                                                </Grid>
                                        }
                                    </React.Fragment>
                                ))
                            )
                        }
                    })
                }
                <Grid item xs={12} className="mt-2">
                    <Typography component="span" variant="h3" className="text-violet-300 font-weight-700"><b>Coursera</b></Typography>
                </Grid>
                <Grid item xs={6} className="mt-2">
                    {data.allies.coursera ?
                        <Typography variant="body1" className="text-fill-stroke-500 semibold font-weight-600"><i className="ri-mail-fill text-fill-stroke-100 mr-2"></i>{data.allies.coursera}</Typography>
                        :
                        <Typography variant="body2" className="text-dark-black-200 font-weight-600">Sin acceso.</Typography>}
                </Grid>
                <Grid item xs={12} className="mt-2">
                    <Typography component="span" variant="h3" className="text-violet-300 font-weight-700"><b>Linkedin Learning</b></Typography>
                </Grid>
                <Grid item xs={6} className="mt-2">
                    {data.allies.linkedin_learning ?
                        <Typography variant="body1" className="text-fill-stroke-500 semibold font-weight-600"><i className="ri-mail-fill text-fill-stroke-100 mr-2"></i>{data.allies.linkedin_learning}</Typography>
                        :
                        <Typography variant="body2" className="text-dark-black-200 font-weight-600">Sin acceso.</Typography>}
                </Grid>
                <Grid item xs={12} className="mt-2">
                    <Typography component="span" variant="h3" className="text-violet-300 font-weight-700"><b>Crehana</b></Typography>
                </Grid>
                <Grid item xs={6} className="mt-2">
                    {data.allies.crehana ?
                        <Typography variant="body1" className="text-fill-stroke-500 semibold font-weight-600"><i className="ri-mail-fill text-fill-stroke-100 mr-2"></i>{data.allies.crehana}</Typography>
                        :
                        <Typography variant="body2" className="text-dark-black-200 font-weight-600">Sin acceso.</Typography>}
                </Grid>
            </Grid>
            <Toast {...toastProps} onFinished={() => onErrorMessage()} />
        </React.Fragment>
    )
};

export default UserNeoInfo;