import React, { useState }  from 'react';

import { Grid, Box, Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputFileButton from '../../../../design-system/components/Form/InputFileButton/InputFileButton';

const useStyles = makeStyles((theme) => ({
    preview:{
        display: 'flex',
        borderRadius: 8,
        height: 362,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    previewData: {
        display: 'flex',
        gridGap: "16px",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    playlistNameLabel: {
        color: theme.palette.white,
        textAlign: 'center',
    }   
}));

const CoverPreview = ({onChangeFile, name, path, playlistName}) => {
    const classes = useStyles();
    const [fileUrl, setFileUrl] = useState();

    const handleFileChange = (files) => {
        onChangeFile({ name, value: files[0] });
        setFileUrl(URL.createObjectURL(files[0]));
    };

    // EFFECTS
    React.useEffect(() => {
        setFileUrl(path);
    }, [path]);
    

    return (
        <Grid container>
            <Grid item xs={6}>
                <Box display='flex' height={1} justifyContent='center' flexDirection='column' gridGap={8}>
                    <InputFileButton
                        onFileChange={handleFileChange}
                        label='Imagen de portada'
                        textPrimary='Subir imagen'
                        textSecondary='Volver a subir' 
                        buttonProps={{variant: 'outlined', color:'primary'}}
                    />
                    <Typography className='text-dark-black-200' variant='body2'>tamaño de imagen 288 x 362</Typography>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Typography className='mb-2 text-dark-black-200 semi-bold' variant='body2'>Previsualización:</Typography>
                <Box className={classes.preview} style={{background: fileUrl ? `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${fileUrl}) lightgray 50% / cover no-repeat` : '#A0A9BA'}}>
                    <Box className={classes.previewData}>
                        <Icon className='ri-graduation-cap-fill text-white font-size-32' />
                        { !!playlistName && !!fileUrl && <Typography variant='h3' className={classes.playlistNameLabel}>{playlistName}</Typography>}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CoverPreview;