import * as paths from './paths';

// Components
import Login from '../pages/Login/Login';
import Auth from '../pages/Login/Auth/Auth';

const session = [
    {
        path: paths.LOGIN,
        component: Login,
    },
    {
        path: paths.AUTH,
        component: Auth,
    },
];

export default [...session,];
