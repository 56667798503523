import React from "react";
import { Grid, TextField } from "@material-ui/core";

const UserData = (props) => {
    const { data } = props;
    return (
        <Grid container spacing={4} className="mt-2">
            <Grid item xs={12}>
                <TextField
                    label="Nombre completo"
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={`${data.first_name} ${data.last_name}`}
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Matrícula"
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.user_id || ""}
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="¿Es Admin?"
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.is_admin ? "Sí" : "No"}
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="¿Esta activo?"
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.is_active ? "Sí" : "No"}
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="External ID"
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.external_id || ""}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Fecha de última actividad"
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={(data.activity_date && new Date(data.activity_date).toLocaleString()) || ""}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Orígen"
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={data.origin_id ? `${data.origin_id} - ${data.person_origin_name}` : ""}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Fecha de creación"
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={(data.created_date && new Date(data.created_date).toLocaleString()) || ""}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
};

export default UserData;