import React from 'react';

// Material components
import { Box, Typography, makeStyles } from '@material-ui/core';

// Shared components
import InputSearch from '../../../../design-system/components/Form/InputSearch/InputSearch';

// Components
import ItemUserList from './ItemUserList/ItemUserList';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    },
    userList: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "250px",
        overflow: "auto",
        gap: "8px"
    }
}));

const UserList = ({users, onCheckExcludedPerson, onChangeFilterUser, filterUser}) => {
    const classes = useStyles();

    // HANLDES
    const handleRemoveFilterUser = () => {
        onChangeFilterUser("");
    };

    return (
        <Box className={clsx(classes.root)}>
            <Box width="100%">
                <Typography variant='body2'>Usuarios agregados</Typography>
            </Box>
            <Box width="100%">
                <InputSearch
                    value={filterUser}
                    onChange={onChangeFilterUser}
                    onClickDelete={handleRemoveFilterUser}
                    placeholder={"Buscar usuarios por nombre, tag o correo electrónico..."}
                />
            </Box>
            <Box className={clsx(classes.userList)}>
                {
                    !!users && users.map(user => <ItemUserList key={user.person_id} user={user} onCheckExcludedPerson={onCheckExcludedPerson}/>)
                }
            </Box>
        </Box>
    );
};

export default UserList;