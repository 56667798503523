import React from 'react';

import LogoSource from '../../design-system/components/LogoSource/LogoSource';
import Logout from '../../pages/Login/Logout/Logout';
import { SOURCES } from '../../design-system/constants/sources';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const Header = () => {
    return (
        <AppBar position="fixed" color='inherit' elevation={0}>
            <Toolbar>
                <LogoSource sourceId={SOURCES.TALISIS} color='blackColor' />
                <Box display="flex" alignItems="center" ml="auto">
                    <Logout>
                        <Button
                            color='primary'
                            endIcon={<Icon className="ri-logout-box-r-line" />}
                            className="text-decoration-none">
                            Cerrar sesión
                        </Button>
                    </Logout>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;