import React from "react";
import {Box, Button, CircularProgress, Divider, Grid, TextField} from "@material-ui/core";

const Emails = (props) => {
    const {key_id, data: email, HCClasses, HCProps: {onClick, loading}, bannerId} = props;

    const handleEmailChange = (newValue) => {
        email.email_address = newValue;
    };

    return (
        <React.Fragment key={key_id}>
            <Grid item xs={3}>
                <TextField
                    label="Correo electrónico"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        className: "bg-gray-200",
                    }}
                    variant="outlined"
                    defaultValue={email.email_address}
                    onChange={e => handleEmailChange(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    label="Código"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    variant="outlined"
                    value={email.email_code}
                    fullWidth
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="¿Principal?"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    variant="outlined"
                    value={email.is_main ? "Sí" : "No"}
                    fullWidth
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="¿Activo?"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    variant="outlined"
                    value={email.is_active ? "Sí" : "No"}
                    fullWidth
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="¿Login?"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        readOnly: true,
                        className: "bg-gray-200",
                    }}
                    variant="outlined"
                    value={email.is_used_for_login ? "Sí" : "No"}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4} className={HCClasses.displayInlineFlex}>
                <Button variant="contained" color="primary" size="medium" className="mt-1" disabled={loading}
                        onClick={() => {
                            onClick(key_id, email.person_id, email.id, email.email_address, email.email_code, bannerId);
                        }}>
                    {loading ?
                        <Box style={{width: 255}}>
                            <CircularProgress style={{width: 20, height: 20}}/>
                        </Box>
                        :
                        '¿Establecer como correo principal?'
                    }
                </Button>
            </Grid>
        </React.Fragment>
    )
};

export default Emails;