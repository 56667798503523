import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { Typography, Box, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.input.label
    }
}));

const InputFileButton = ({ onFileChange, initialFileName, accept, label, buttonProps, textPrimary, textSecondary }) => {
    const classes = useStyles();
    
    const [fileName, setFileName] = useState(initialFileName || '');

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFileName = files[0].name;
            onFileChange(files, newFileName);
            setFileName(newFileName);
        }
    };

    return (
        <Box display="flex" gridGap={8} flexDirection='column'>
            <Typography className={clsx('semi-bold',classes.label)}>{label}</Typography>
            <Box display="flex" alignItems='center' gridGap={8}>
                <Input
                    type="file"
                    inputProps={{ accept }}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="file-upload"
                />
                <label htmlFor="file-upload">
                    <Button
                        component="span"
                        startIcon={<Icon className='ri-upload-2-line' />}
                        {...buttonProps}
                    >
                        {initialFileName ? textSecondary : textPrimary}
                    </Button>
                </label>
                <Typography variant='body2' className='semi-bold text-blue-400'>
                    {fileName}
                </Typography>
            </Box>
        </Box>
    );
};

InputFileButton.defaultProps = {
    label: '',
    accept: '',
    textPrimary: 'Subir',
    textSecondary: 'Volver a subir',
    buttonProps:{
        variant: 'contained',
        color: 'primary'
    }
};

InputFileButton.propTypes = {
    label: PropTypes.string,
    accept: PropTypes.string,
    textPrimary: PropTypes.string,
    textSecondary: PropTypes.string,
    initialFileName: PropTypes.string,
    onFileChange: PropTypes.func.isRequired,
};

export default InputFileButton;
