import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    chip:{
        ...theme.typography.bodyTextXsmallRegular,
        padding: '4px 16px',
        maxWidth: 192,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& .MuiChip-label':{
            padding: 0
        }
    },
    root:{
        '& .icon-file':{
            fontSize: 40
        },
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            gridGap: 16,
            width: '100%',
            '& .icon-file':{
                fontSize: 26
            }
        },
        document:{
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 4
        },
        fileDetail:{
            '&.inline':{
                maxWidth: 180
            }
        },
        chip:{
            height: 20,
            padding: '2px 8px',
            maxWidth: 140,
            '& .MuiChip-label':{
                fontSize: '10px !important'
            }
        },
    },
    tooltipContainer: {
        backgroundColor: "#C4C4C4 !important",
        color: `${theme.palette.colors.fillStroke[400]} !important`,
    },
    tooltipArrowContainer: {
        color: "#C4C4C4 !important",
    },
    ...theme.styles.fileDetailStyle
}));