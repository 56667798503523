import React from 'react';

import { Typography, Card, CardContent, Box } from '@material-ui/core';

const TitleBanner = ({children, title, desc, boxProps}) => {
    return (
        <Card>
            <CardContent>
                <Box display='flex' justifyContent='space-between' {...boxProps}>
                    <Box maxWidth={480}>
                        <Typography variant='h3' className='mb-2'>{title}</Typography>
                        <Typography variant='body2' className='text-dark-black-200'>{desc}</Typography>
                    </Box>
                    <Box>
                        {children}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default TitleBanner;