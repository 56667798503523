import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `0.5px solid ${palette.colors.fillStroke[50]}`,
  },
  body:{
    color: `${palette.colors.darkBlack[500]} !important`
  },
  head: {
    borderBottom: 'unset',
    color: `${palette.colors.violet[300]}!important`,
  },
  stickyHeader:{
    backgroundColor: palette.white,
    padding: '6px 16px',
    '& .MuiTypography-root':{
      ...typography.captionLargeSemiBold,
      color: `${palette.colors.violet[300]}!important`,
    },
    '&:first-child': {
      paddingLeft: 18
    },
    '&:last-child': {
      paddingRight: 18
    }
  }
};
