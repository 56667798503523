import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import clsx from 'clsx';
import { Box } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: 16,
        '& .MuiDrawer-paper':{
            left: 'auto',
            background: 'transparent',
            borderRight: 0,
            position: 'relative'
        }
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '56px !important',
        [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        ...theme.mixins.toolbar,
    },
    buttonToggle:{
    },
    list:{
        padding: 0
    },
    listItem:{
        '&:hover':{
            borderRadius: 8,
        },
        '&.Mui-selected, &.Mui-selected:hover':{
            background: theme.palette.colors.darkBlack[200],
            borderRadius: 8,
        },
    },
}));

export default function ExpandableSidebar({menu = [], onClickItem}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleDrawerToggle = () => {
        setOpen(prev => !prev);
    };

    React.useEffect(() => {
        setOpen(true)
    }, [menu])

    if(!menu.length){
        return null;
    }

    return (
        <div className={classes.root}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}></div>
                <List className={classes.list}>
                    {menu.map((menuItem, index) => (
                            <ListItem button 
                                selected={menuItem.active} 
                                key={`list-item-exp-${index}`} 
                                className={classes.listItem} 
                                onClick={onClickItem.bind(this,menuItem)}>
                                <ListItemIcon>
                                    <Icon className={clsx(menuItem.icon, `${menuItem.active ? 'text-white' : 'text-dark-black-200'}`)} />
                                </ListItemIcon>
                                <ListItemText primary={menuItem.label} 
                                    primaryTypographyProps={{
                                    variant: 'body1', 
                                    className: menuItem.active ? 'text-white' : 'text-dark-black-200'
                                }}/>
                            </ListItem>
                        ))}
                </List>
                <Box mt="auto" textAlign='end'>
                    <IconButton color='secondary' onClick={handleDrawerToggle} className={clsx(classes.buttonToggle)}>
                        <Icon className={open ? 'ri-arrow-left-s-line' : 'ri-arrow-right-s-line'}/>
                    </IconButton>
                </Box>
            </Drawer>
        </div>
    );
}
