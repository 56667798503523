import React, { useState, useEffect } from 'react';

// Material components
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

// Shared components 
import DrawerContainer from './DrawerContainer';


const useStyles = makeStyles((theme) => ({
    boxContainer:{
        padding: '32px 24px 122px'
    },
}))

const DrawerButtonContainer = ({ children, textBttn, title, loading, onClickButton, open: controlledOpen, ...rest }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (controlledOpen !== undefined) {
            setOpen(controlledOpen);
        }
    }, [controlledOpen]);

    const handleClickOpen = () => {
        setOpen(prev => !prev);
        if (onClickButton) {
            onClickButton(!open);
        }
    };

    return (
        <React.Fragment>
            <Button variant='contained' color='primary' onClick={handleClickOpen} {...rest}>
                {textBttn}
            </Button>

            <DrawerContainer
                loading={loading}
                title={title}
                open={open}
                handleClickOpen={handleClickOpen}
                classes={classes}>
                {children}
            </DrawerContainer>
        </React.Fragment>
    );
};

export default DrawerButtonContainer;
