export const HCCSSClasses = (theme) => ({
    loader: {
        margin: "auto",
        position: "relative",
    },
    loaderButton: {
        height: "auto !important",
        width: "18px !important",
    },
    box: {
        alignItems: "flex-start",
        background: theme.palette.colors.gray[50],
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        display: "flex",
        flex: "none",
        flexDirection: "column",
        flexGrow: 0,
        gap: "8px",
        justifyContent: "center",
        padding: "16px",
        order: 0,
    },
    boxLoader: {
        alignItems: "flex-start",
        background: theme.palette.colors.gray[50],
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        display: "flex",
        flex: "none",
        flexGrow: 0,
        gap: "8px",
        justifyContent: "center",
        padding: "16px",
        order: 0,
    },
    boxSearch: {
        alignItems: "flex-start",
        background: theme.palette.colors.gray[50],
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        display: "inherit",
        flex: "none",
        flexDirection: "column",
        flexGrow: 0,
        gap: "24px",
        justifyContent: "center",
        padding: "16px",
        textAlign: "center",
        order: 0,
    },
    accordion: {
        background: theme.palette.colors.gray[50],
        borderTop: `0.5px solid ${theme.palette.colors.fillStroke[50]}`,
        marginBottom: 0,
        "&.MuiAccordion-root.Mui-expanded": {
            margin: 0,
        },
    },
    accordionHeader: {
        borderRadius: "8px 8px 0px 0px",
    },
    accordionSummary: {
        background: theme.palette.colors.gray[50],
        border: 0,
        borderRadius: "0px 0px 0px 0px",
        padding: "0px 16px",
    },
    accordionSummaryLast: {
        borderRadius: "0px 0px 8px 8px",
    },
    accordionDetails: {
        padding: "0px 16px 16px",
        display: "block !important",
    },
    note: {
        color: theme.palette.colors.grey[1000],
    },
    gridContainer: {
        border: `1px solid ${theme.palette.colors.grey[50]}`,
        borderRadius: "8px",
        padding: "16px !important",
    },
    paddingZero: {
        padding: "0px !important",
    },
    separator: {
        border: `1px solid ${theme.palette.colors.fillStroke[50]}`,
        width: "100%",
    },
    textAlignCenter: {
        textAlign: "center",
    },
    textAlignRight: {
        textAlign: "right",
    },
    textAlignLeft: {
        textAlign: "left",
    },
    displayInlineFlex: {
        display: 'inline-flex',
    },
    valign_center: {
        display: "inline-flex",
        alignItems: "center",
    },
});