import React from 'react';

import { userIsLoggedIn } from '../Session/Session';
import AuthScreen from '../AuthScreen/AuthScreen';
import AuthAnimation from './Animation/AuthRedirect.json';

import Lottie from 'react-lottie';
import { ROOT_HOME } from '../../../routes/paths';

// @material-ui/core
import { Box, makeStyles, } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    animatedLoader: {
        width: '361px !important',
    },
    img: {
        position: 'absolute',
        margin: 'auto',
        left: 0,
        right: 0,
        top: 90,
        textAlign: 'center',
    },
}));

const Auth = (props) => {
    const classes = useStyles();
    
    React.useEffect(() => {
        if (userIsLoggedIn()) {
            props.history.push(ROOT_HOME);
        }
    }, [props]);

    return (
        <React.Fragment>
            <Box mt={14} textAlign={'center'}>
                <Lottie options={{
                    loop: true,
                    autoplay: true,
                    animationData: AuthAnimation,
                    rendererSettings: {
                        className: classes.animatedLoader
                    }
                }} width={445} height={342}/>
            </Box>
            <AuthScreen {...props} />
        </React.Fragment>
    )
};

export default Auth;