import palette from '../palette';

export default {
    root: {
        // backgroundColor: palette.background.,
        borderRadius: 8,
        // boxShadow: 'unset',
        '& .MuiCardContent-root':{
            padding: '16px !important'
        },
        '&.MuiPaper-outlined':{
            backgroundColor: palette.colors.fillStroke[400],
            border: `1px solid ${palette.colors.fillStroke[200]}`,
        },
        '&.MuiPaper-elevation0':{
            backgroundColor: 'transparent',
        },
        '&.MuiPaper-elevation2':{
            backgroundColor: palette.colors.gray[200],
            border: 0,
            boxShadow: 'unset',
        },
        '&.MuiPaper-elevation3':{
            background: 'rgba(159, 111, 251, 0.16) !important',
            border: `0.5px solid ${palette.colors.violet[300]} !important`
        },
        '&.MuiPaper-elevation4':{
            backgroundColor: palette.colors.fillStroke[400],
            border: `.5px solid ${palette.colors.fillStroke[100]}`
        },
        '&.MuiPaper-elevation5':{
            backgroundColor: palette.colors.fillStroke[500],
            border: `.5px solid ${palette.colors.fillStroke[200]}`
        },
        '&.primary-hover':{
            cursor: 'pointer',
            '&:hover':{
                backgroundColor: palette.colors.violet[300],
            }
        }
    },
};
