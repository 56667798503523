import React from 'react';
import { useMsal, } from "@azure/msal-react";

import { log } from '../../../design-system/utils/console';
import * as Session from '../Session/Session';

import AuthScreen from '../AuthScreen/AuthScreen';

// @material-ui
import { Grid, Box, TextField, Button, CircularProgress } from '@material-ui/core';
import Toast from '../../../design-system/components/Toast/Toast';

// @SDK
import { LoginService, UserService } from '@sdk-point/talisis';
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const LoginServiceSDK = new LoginService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const SplitLogin = (props) => {
    const { instance, } = useMsal();
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        'email': '',
        'password': '',
    });
    const [company, setCompany] = React.useState({
        sso: '',
        ou: '',
        client_id: '',
        ouLabel: '',
        ouId: ''
    });
    const [errors, setErrors] = React.useState({});
    const [alertProps, setAlertProps] = React.useState({
        open: false,
        severity: "success",
        duration: 4000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            const _errors = { ...errors };
            delete _errors[name];
            setErrors(_errors);
        }

        setForm(prev => {
            let clean_value = value.trim();
            if (name === "email") {
                clean_value = clean_value.toLowerCase();
            }

            return { ...prev, [name]: clean_value }
        });
    };

    const validate = () => {
        const _errors = {};
        const requiredFields = ['email'];
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        for (const field of requiredFields) {
            if (!form[field]) {
                _errors[field] = 'Campo Requerido';
            }
            else {
                if (!pattern.test(form[field])) {
                    _errors[field] = 'Introduce un correo electrónico válido';
                }
            }
        }

        return Boolean(Object.keys(_errors).length === 0);
    };

    const validateIsAdmin = async () => {
        try {
            let isAdmin = false;
            const { data: personData } = await UserServiceSDK.getByEmail(form.email, true);
            if (personData) {
                for (let person of personData) {
                    const roles = await UserServiceSDK.getRolesByUser(person.id);
                    if (roles) {
                        for (let role of roles) {
                            if (role.role_id === 2) {
                                isAdmin = true;
                                break;
                            }
                        }
                    }
                    if (isAdmin) {
                        break;
                    }
                }
            }

            return isAdmin;
        }
        catch (error) {
            log(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            const isAdmin = await validateIsAdmin();
            if (isAdmin) {
                const tenantData = await LoginServiceSDK.getSplitLogin(form.email);
                setCompany(tenantData);
            }
            else {
                log("Splitlogin.jsx ¡No eres admin!");
                setAlertProps({ ...alertProps, open: true, severity: 'error', message: "Acceso sólo a usuarios administradores." });
            }
            setLoading(false);
        }
    };

    const msLogin = async () => {
        try {
            (async () => {
                const msLoginRequest = {
                    prompt: "consent",
                    account: { userName: form.email },
                    loginHint: form.email,
                    domainHint: company?.domainHint,
                    extraQueryParameters: {
                        env: process.env.REACT_APP_ENV,
                    },
                    redirectStartPage: '/auth',
                    onRedirectNavigate: () => {
                        Session.setCookie(Session.TMP_LOGIN_START_COOKIE, true);
                    },
                };
                log(`Splitlogin.jsx msLoginRequest:`);
                log(msLoginRequest);
                await instance.loginRedirect(msLoginRequest)
                    .catch((e) => {
                        log(`Splitlogin.jsx loginRedirectError: ${e}`);
                    });
            })();
        }
        catch (err) {
            setCompany({});
        }
    };

    const hanldeFinishedToast = () => {
        setAlertProps({ ...alertProps, open: false, message: "", });
    }

    React.useEffect(() => {
        const fetch = async () => {
            if (company.sso === 'microsoft') {
                await msLogin();
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    return (
        <React.Fragment>
            <Box>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                    className: 'text-gray-100 semi-bold'
                                }}
                                InputProps={{
                                    className: 'text-gray-100'
                                }}
                                name="email"
                                variant="outlined"
                                label="Correo electrónico"
                                fullWidth
                                size="medium"
                                required
                                value={form.email || ""}
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                                onChange={handleChange}
                                placeholder="Ingresa tu correo electrónico"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" className="offset-margin-bottom-32">
                                <Button fullWidth variant="contained" color="primary" type={'submit'} onClick={handleSubmit}>
                                    {loading ? <CircularProgress size="1rem" /> : 'Iniciar sesión'}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <AuthScreen {...props} />
                </form>
            </Box>
            <Toast {...alertProps} onFinished={hanldeFinishedToast} />
        </React.Fragment>
    )
}

export default SplitLogin;