import palette from '../palette';

export default {
  root:{
    backgroundColor: palette.colors.white,
  },
  outlined:{
    border:' 0.5px solid #828D9E'
  },
  elevation1: {
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.10)'
  },
  elevation2:{
      background: '#1C1F24 !important',
      border: '0.5px solid #404150 !important',
      boxShadow: 'unset',
      borderRadius: '8px !important'
  },
  elevation3:{
    borderRadius: 8,
    border: '0.5px solid #404150',
    background: '#111'
  }
};
