export const ACTIONS = {
    TALISIS_USER_INFO: "TALISIS_USER_INFO",
    FIND_NEO_USER: "FIND_NEO_USER",
    REMOVE_NEO_STUDENT_FROM_CLASS: "REMOVE_NEO_STUDENT_FROM_CLASS",
    ARCHIVE_NEO_USER: "ARCHIVE_NEO_USER",
    SET_USER_PRINCIPAL_EMAIL: "SET_USER_PRINCIPAL_EMAIL",
    ACCOUNT_UNIFICATION_REQUEST: "ACCOUNT_UNIFICATION_REQUEST",
    FIX_CLASS_NEO: "FIX_CLASS_NEO",
    UPDATE_USER_EMAIL: "UPDATE_USER_EMAIL",
};
