import React from 'react';
import clsx from 'clsx';

// Material components
import { Box, Checkbox, FormControlLabel, Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textEllipsis: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            fontSize: "16px"
        }
    },
}));

const ItemUserList = ({user, onCheckExcludedPerson}) => {
    const classes = useStyles();

    return (
        <Box padding="12px">
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box display="flex" flexDirection="column">
                        <Typography className={clsx("variant-xsmall", "text-dark-black-500")}>Nombre</Typography>
                        <Typography className={clsx("variant-xsmall", classes.textEllipsis, "text-dark-black-200")}>{user.person_name ?? ""}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box display="flex" flexDirection="column">
                        <Typography className={clsx("variant-xsmall")}>Tag</Typography>
                        <Typography className={clsx("variant-xsmall", classes.textEllipsis, "text-dark-black-200")}>{user.tags ?? ""}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Box display="flex" flexDirection="column">
                        <Typography className={clsx("variant-xsmall")}>Correo electrónico</Typography>
                        <Typography className={clsx("variant-xsmall", classes.textEllipsis, "text-dark-black-200")}>{user.email ?? ""}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Checkbox
                            color='primary'
                            checked={user.checkedExcluded}
                            onChange={() => onCheckExcludedPerson(user.person_id)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            className={clsx(classes.checkbox)}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ItemUserList;