import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as Session from '../pages/Login/Session/Session';
import PropTypes from 'prop-types';
import Layout from '../layout/Main';
import * as paths from './paths';

const RouteWithLayout = props => {
    const {component: Component, ...rest } = props;
    const TMP_LOGIN_START_COOKIE = Session.getCookie(Session.TMP_LOGIN_START_COOKIE);
    return (
        <Route {...rest} 
            render={matchProps => (
                TMP_LOGIN_START_COOKIE ?
                    <Redirect to={paths.LOGIN} />
                    :
                    <Layout {...matchProps} {...rest}>
                        <Component {...matchProps} />
                    </Layout>
            )}
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any,
    path: PropTypes.string
};

export default RouteWithLayout;
