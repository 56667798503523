import palette from '../palette';

export default {
    root:{
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        textTransform: 'capitalize',
        alignItems: 'end',
        padding: '6px 12px 7px 12px',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
        '@media (max-width:600px)': {
            fontSize: 14,
        },
    },
    textColorInherit:{
        color: palette.colors.darkBlack[100],
        '&.Mui-selected':{
            color: palette.colors.darkBlack[300] + '!important',
            fontWeight: 600
        }
    }
};