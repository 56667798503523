import React from 'react';
import { useMsal, } from "@azure/msal-react";
import { EventType, } from "@azure/msal-browser";
import { loginRequest, } from '../../../config/authConfig';
import * as Session from '../Session/Session';
import { log } from '../../../design-system/utils/console';

// @redux
import { connect } from 'react-redux';
import * as userActions from '../../../redux/actions/userActions';
import * as menuActions from '../../../redux/actions/menuActions';

// @SDK
import { LoginService, } from '@sdk-point/talisis';
const LoginServiceSDK = new LoginService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const AuthScreen = (props) => {
    const { instance, accounts } = useMsal();

    const getCompany = async domain => {
        log('AuthScreen.jsx Entró al get company con el dominio: ', domain);
        switch (domain) {
            case 'red.unid.mx':
                return { ou: 'unid', client_id: 'unid', ouLabel: 'UNID', ouId: 'UNID' };
            case 'unid.mx':
                return { ou: 'unid', client_id: 'unidStaff', ouLabel: 'UNID', ouId: 'UNID' };
            case 'u-erre.mx':
                return { ou: 'uerre', client_id: 'uerre', ouLabel: 'U-ERRE', ouId: 'UERRE' };
            case 'u-erre.edu.mx':
                return { ou: 'uerre', client_id: 'talisis', ouLabel: 'U-ERRE', ouId: 'UERRE' };
            case 'iesalud.edu.mx':
                return { sso: 'google', ou: 'iesalud', client_id: 'uerre', ouLabel: 'IESALUD', ouId: 'IESALUD' };
            case 'talisis.com':
                return { ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL' };
            case 'miadvenio.com':
                return { ou: 'advenio', client_id: 'miAdvenio', ouLabel: 'Advenio', ouId: 'ADV' };
            case 'harmonhall.edu.mx':
                return { sso: 'google', ou: 'hh', client_id: 'uerre', ouLabel: 'Harmon Hall', ouId: 'HH' };
            case 'al.harmonhall.edu.mx':
                return { sso: 'google', ou: 'hh', client_id: 'uerre', ouLabel: 'Harmon Hall', ouId: 'HH' };
            case 'soy.harmonhall.edu.mx':
                return { ou: 'hh', client_id: 'soyHH', ouLabel: 'Harmon Hall', ouId: 'HH' };
            case 'familia.talisis.com':
                return { ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL' };
            default:
                return { ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL' };
        }
    }

    const signIn = async () => {
        log(`AuthScreen.jsx Entro a signIn()`);
        try {
            await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
                .then(async (response) => {
                    const b2cUser = response.idTokenClaims;
                    const email = b2cUser.email;
                    const domain = email.substring(email.lastIndexOf("@") + 1);
                    const domainData = await getCompany(domain);
                    let profile = {
                        userName: b2cUser.given_name,
                        email: email,
                        ou: domainData.ou
                    };
                    log(`AuthScreen.jsx profile:`);
                    log(profile);
                    await LoginServiceSDK.studentCode(profile)
                        .then(async (student) => {
                            log(`AuthScreen.jsx student:`);
                            log(student);
                            if (student.error) {
                                const isFixed = await LoginServiceSDK.fixLogin({ email });
                                if (isFixed.fixed) {
                                    await LoginServiceSDK.studentCode(profile)
                                        .then(async student => {
                                            log(`AuthScreen.jsx student:`);
                                            log(student);
                                            if (student.error) {
                                                // ...
                                            }
                                            else {
                                                const userInfo = {
                                                    userName: `${student.first_name} ${student.last_name}`,
                                                    first_name: student.first_name,
                                                    last_name: student.last_name,
                                                    email: profile.email,
                                                    student_id: student.student_id,
                                                    person_id: student.person_id,
                                                    is_admin: student.is_admin,
                                                    gender: student.gender,
                                                    phone_number: student.phone_number,
                                                    ou: domainData.ou,
                                                    ouLabel: domainData.ouLabel,
                                                    company_id: domainData.ouId,
                                                    company: domainData.ouId,
                                                    SSO: true,
                                                    membership_id: student.membership_id || 1,
                                                    interests: student.interests || [],
                                                    profile_picture_url: student.profile_picture_url,
                                                };
                                                if (student.is_active) {
                                                    props.applyUser(userInfo);
                                                    props.applyPermission(userInfo)
                                                    log(`AuthScreen.jsx userInfo:`);
                                                    log(userInfo);
                                                    props.history.push('/');
                                                }
                                                else {
                                                    // ...
                                                };
                                            }
                                        });
                                }
                                else {
                                    // ...
                                }
                            }
                            else {
                                const userInfo = {
                                    userName: `${student.first_name} ${student.last_name}`,
                                    first_name: student.first_name,
                                    last_name: student.last_name,
                                    email: profile.email,
                                    student_id: student.student_id,
                                    person_id: student.person_id,
                                    is_admin: student.is_admin,
                                    gender: student.gender,
                                    phone_number: student.phone_number,
                                    ou: domainData.ou,
                                    ouLabel: domainData.ouLabel,
                                    company_id: domainData.ouId,
                                    company: domainData.ouId,
                                    SSO: true,
                                    membership_id: student.membership_id || 1,
                                    interests: student.interests || [],
                                    profile_picture_url: student.profile_picture_url,
                                };
                                if (student.is_active) {
                                    props.applyUser(userInfo);
                                    props.applyPermission(userInfo)
                                    log(`AuthScreen.jsx userInfo:`);
                                    log(userInfo);
                                    props.history.push('/');
                                }
                                else {
                                    // ...
                                };
                            }
                        });
                })
                .catch(e => {
                    log(`AuthScreen.jsx error: ${e}`);
                });
        }
        catch (error) {
            log(`AuthScreen.jsx error: ${error}`);
        }
    }

    React.useEffect(() => {
        // This will be run on component mount
        const callbackId = instance.addEventCallback(async (event) => {
            log(`AuthScreen.jsx msal instance event:`);
            log(event);

            // This will be run every time an event is emitted after registering this callback
            switch (event.eventType) {
                case EventType.INITIALIZE_START:
                    Session.removeCookie(Session.TMP_LOGIN_START_COOKIE);
                    break;
                case EventType.LOGIN_START:
                    Session.setCookie(Session.TMP_LOGIN_START_COOKIE, true);
                    break;
                case EventType.LOGIN_SUCCESS:
                    log(`AuthScreen.jsx Login exitoso`);
                    const account = event?.payload?.account;
                    if (account) {
                        instance.setActiveAccount(account);
                        await signIn();
                    }
                    break;
                case EventType.LOGIN_FAILURE:
                    Session.removeSessionCookie();
                    break;
                default:
                    // ..
                    break;
            }
        });

        return () => {
            // This will be run on component unmount
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<></>);
}

const mapStateToProps = ({ userReducer, menuReducer }) => ({
    ...userReducer, ...menuReducer
});
const mapDispatchToProps = { ...userActions, ...menuActions };
export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);
