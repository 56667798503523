import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useHistory, } from 'react-router-dom';
import * as Session from '../pages/Login/Session/Session';
import { log } from '../design-system/utils/console';

import pages from './pages';
import RouteWithLayout from './RouteWithLayout';
import * as paths from './paths';

// layouts
import Reports from '../pages/Reports/Reports';
import Login from '../pages/Login/Login';
import publicRoutes from './publicRoutes';

const getPathItems = (items) => {
    return items.flatMap(item => {
        if (item.path) {
            return [item];
        }
        if (item.children) {
            return getPathItems(item.children);
        }
        return [];
    });
};

const Routes = () => {
    const history = useHistory();
    const routes = getPathItems(pages);
    
    const isLoggedIn = Session.userIsLoggedIn();
    const TMP_LOGIN_START_COOKIE = Session.getCookie(Session.TMP_LOGIN_START_COOKIE);
    const userIsLoggedIn = isLoggedIn || TMP_LOGIN_START_COOKIE ? true : false;
    log(`Routes.jsx Esta o intenta loguearse: ${userIsLoggedIn}`);

    return (
        <Switch>
            {!userIsLoggedIn && <Route exact path="*"><Redirect to={paths.LOGIN} /><Login history={history} /></Route>}
            {
                publicRoutes.map(({ path, component: Component }, index) => (
                    <Route exact key={index} path={path}
                        render={matchProps => (
                            <Component {...matchProps} history={history} />
                        )}
                    />
                ))
            }
            <RouteWithLayout path="/" exact component={Reports} />
            {
                routes.map((it, index) => (
                    <RouteWithLayout exact key={index} {...it}  />
                ))
            }
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    );
};

export default Routes;
