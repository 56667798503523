import { Box, Drawer, Icon, IconButton, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import LoaderContent from '../Loader/LoaderContent';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        padding: '32px 24px 16px'
    },
}));

const DrawerContainer = ({title, anchor, open, handleClickOpen, size, children, loading}) => {
    const classes = useStyles();

    return (
        <Drawer anchor={anchor} open={open} onClose={handleClickOpen}>
            <LoaderContent loading={loading} height="100%">
                <Box width={size}>
                    <Box display='flex' className={classes.titleContainer}justifyContent='space-between' alignItems='center'>
                        <Typography variant="h4">{title}</Typography>
                        <IconButton className='p-0' onClick={handleClickOpen}><Icon className='ri-close-line text-dark-black-200' /></IconButton>
                    </Box>
                    {children}
                </Box>
            </LoaderContent>
        </Drawer>
    );
};

DrawerContainer.defaultProps = {
    anchor: "right",
    size: 598
};

export default DrawerContainer;