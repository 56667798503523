import * as userTypes from '../types/userTypes';
import { log } from '../../design-system/utils/console';
import { UserService } from '@sdk-point/talisis';


export const applyPermission = (user) => (dispatch) => {
    try {
        const userService =  new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)
        user = {
            platform_id: 1,
            ...user
        }
        userService.getPermissionsAxios(user).then(response => {
            localStorage.setItem("permissions", JSON.stringify(response));
            dispatch({
                type: userTypes.APPLY_MENU,
                payload: response
            }); 
        })
        .catch(error => {
            log(error)
        });
    } catch(e) {
        log(e)
    }
};