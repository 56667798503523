import React from 'react';

// Material components
import { Card, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Shared components
import Table from '../../design-system/components/Table/Table';
import Pagination from '../../design-system/components/Table/components/Pagination';
import TitleBanner from '../../design-system/components/Banners/TitleBanner';
import ShowRecord from './ShowRecord/ShowRecord';

import TableFilter from '../../design-system/components/Table/TableFilter/TableFilter';
import SearchByInput from '../../design-system/components/Table/TableFilter/components/SearchByInput/SearchByInput';

import { RecordDocumentService } from '@sdk-point/talisis';
import LoaderContent from '../../design-system/components/Loader/LoaderContent';
const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    table:{
        [theme.breakpoints.down('lg')]:{
            maxHeight: '500px !important'
        },
        [theme.breakpoints.up('xl')]:{
            maxHeight: '650px !important'
        }
    },
}))

const Record = () => {
    const classes = useStyles();

    const [page, setPage] = React.useState(1);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [paginationProps, setPaginationProps] = React.useState({
        count: 1,
        results: 0,
        totalResults: 0
    });

    const [searchFilter, setSearchFilter] = React.useState({
        searchType: 'name',
        search: ''
    });

    React.useEffect(()=>{
        getStudents();
    }, [])

    const getStudents = async (filters = searchFilter, currentPage = 1) => {
        setPage(currentPage);
        setLoading(true);
        try {
            const search = filters.searchType && filters.search ? filters : {};
            const response = await RecordDocumentServiceSDK.getAllStudentsRecordAdmin({page: currentPage, ...search});

            setPaginationProps({
                count: response.total ? Math.ceil(response.total / 10) : 1,
                totalResults: response.total,
                results: response.data.length
            })
    
            const data = response.data.map((item) => ({
                ...item,
                actions: <ShowRecord userId={item.id} fullName={item.full_name}/>
            }));
    
            setRows(data);
            setLoading(false);
        } catch (error) {
            setLoading(false) 
        }
    };

    const handleChangeSearch = (obj) => {
        const {name, value} = obj;

        setSearchFilter({
            searchType: name,
            search: value
        });
    }

    const handleClickDeleteSearch =  (searchType) => {
        const _searchFilter = {
            searchType: searchType || searchFilter.searchType,
            search: ''
        }
        setSearchFilter(_searchFilter);
        getStudents(_searchFilter)
    }

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TitleBanner 
                        title='Expedientes'
                        desc='Consulta el expediente de los alumnos, revisa el historial de documentos
                        aprobados, consúltalo para trámites y servicios.'
                    />
                </Grid>
    
                <Grid item xs={12}>
                    <Card>
                    <LoaderContent loading={loading} minHeight={200}>
                        <TableFilter>
                            <Grid item xs>
                                <SearchByInput 
                                    selectValue={searchFilter.searchType}
                                    searchValue={searchFilter.search} 
                                    items={[
                                        {id: 'user', label: 'Matrícula', placeholder: 'Escribe la matrícula'},
                                        {id: 'name', label: 'Nombre completo', placeholder: 'Escribe el nombre'},
                                        {id: 'email', label: 'Correo electrónico', placeholder: 'Escribe el correo'},
                                    ]}
                                    onChange={handleChangeSearch}
                                    onKeyDown={() => getStudents()}
                                    onClickDelete={handleClickDeleteSearch}
                                    selectContainerProps={{maxWidth: 280}}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' fullWidth onClick={() => getStudents()}>Aplicar</Button>
                            </Grid>
                        </TableFilter>
                        <Table
                            columns={[
                                { name: 'user_id', label: 'Matricula'},
                                { name: 'full_name', label: 'Nombre completo'},
                                { name: 'email_address', label: 'Correo electrónico' },
                                { name: 'actions', label: 'Acciones', align: 'center', width: 200 },
                            ]}
                            rows={rows}
                            loading={loading}
                            className={classes.table}
                            
                        />
                        <Pagination
                            page={page} 
                            onChange={(event, value) =>  getStudents(searchFilter, value)}
                            {...paginationProps}
                        />
                        </LoaderContent>
                    </Card>
                </Grid>
            </Grid>
    );
};

export default Record;