import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Routes';

// @redux
import { connect } from 'react-redux';
import * as userActions from './redux/actions/userActions';
import * as menuActions from './redux/actions/menuActions';

import * as Session from './pages/Login/Session/Session';

import Theme, { LIGHT_THEME } from './design-system/theme'
import lightTheme from './design-system/theme/light'

import './design-system/assets/styles/styles.scss';

function App(props) {
	let sessionCookie = Session.getSessionCookie();
	let permissionRoles = localStorage.getItem("permissions");
	const LOGIN_PLATFORM_TALISIS_COOKIE = Session.getCookie("LOGIN_PLATFORM_TALISIS");

	const handleUnload = () => {
		// ...
	};

	React.useEffect(() => {
		let isLoggedIn = Session.userIsLoggedIn();
		// Bi-directional login with www.talisis.com
		if (LOGIN_PLATFORM_TALISIS_COOKIE && sessionCookie) {
			sessionCookie["ignore_admin_talisis_cookie"] = true;
			if (permissionRoles) {
				for (let permissionRole of permissionRoles) {
					if (permissionRole.role_id === 2) {
						isLoggedIn = true;
						break;
					}
				}
			}
		}

		if (isLoggedIn) {
			props.applyUser(sessionCookie);
			props.applyPermission(sessionCookie);
			// ...
		} else {
			localStorage.clear();
		}

		window.addEventListener("unload", handleUnload);
        return () => {
            window.removeEventListener("unload", handleUnload);
        }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BrowserRouter>
			<div>
				<Theme prefersTheme={LIGHT_THEME} theme={lightTheme}>
					<Routes />
				</Theme>
			</div>
		</BrowserRouter>
	);
}

const mapStateToProps = ({ userReducer, menuReducer }) => ({
	...userReducer, ...menuReducer
});
const mapDispatchToProps = { ...userActions, ...menuActions };
export default connect(mapStateToProps, mapDispatchToProps)(App);
