export default {
    root: {
        // backgroundColor: '#FFFFFF'
    },
    head:{
        '& .MuiTableCell-root': {
            '&:first-child':{
                borderTopLeftRadius: 5,
            },
            '&:last-child':{
                borderTopRightRadius: 5,
            },
        },
    }
};
