import React from 'react';

// Material components
import { Typography, Card, Grid, CardContent, Box, Icon, makeStyles } from '@material-ui/core';

// Shared components
import Table from '../../design-system/components/Table/Table';
import Pagination from '../../design-system/components/Table/components/Pagination';
import MoreOptions from '../../design-system/components/MoreOptions/MoreOptions';
import TableFilter from '../../design-system/components/Table/TableFilter/TableFilter';
import Tabs from '../../design-system/components/Tabs/Tabs';
import InputSearch from '../../design-system/components/Form/InputSearch/InputSearch';
import Select from '../../design-system/components/Form/Select/Select';
import DrawerButtonContainer from '../../design-system/components/Drawer/DrawerButtonContainer';
import LoaderContent from '../../design-system/components/Loader/LoaderContent';
import Toast from '../../design-system/components/Toast/Toast';

// Components
import FormPlaylist from './FormPlaylist/FormPlaylist';
import EdithPlaylist from './EdithPalylist/EdithPlaylist';
import ItemStatusTable from './ItemStatusTable/ItemStatusTable';
import ModalToConfirm from './ModalToConfirm/ModalToConfirm';

// Utils shared
import dateFormat from '../../design-system/utils/dateFormat';
import { DD_MMM_YYYY_FORMAT } from '../../design-system/constants/date-formats';


// @SDK
import { AcademiesService } from '@sdk-point/talisis';
const AcademiesServiceSDK = new AcademiesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

// Constants
const TOTAL_ROWS_TO_SEARCH_TABLE = 10;
const PLAYLIST_ESTATUS_PUBLISH = 1;
const PLAYLIST_ESTATUS_UNPUBLISH = 2;
const PLAYLIST_ESTATUS_ARCHIVED = 3;

const useStyles = makeStyles((theme) => ({
    table:{
        [theme.breakpoints.down('lg')]:{
            maxHeight: '500px !important'
        },
        [theme.breakpoints.up('xl')]:{
            maxHeight: '500px !important'
        }
    },
}))

const Academy = () => {
    const ac = new AbortController();
    const classes = useStyles();
    // STATES
    // -- Table
    const [dataPlaylists, setDataPlaylists] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [rows, setRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
    const [loadingTable, setLoadingTable] = React.useState(false);
    
    // -- Tabs
    const [tabs] = React.useState([
        <Box display='flex' gridGap={8}>Playlist activas</Box>,
        <Box display='flex' gridGap={8}>Playlist archivadas</Box>
    ]);
    const [tabsIndexSelected, setTabsIndexSelected] = React.useState(0);

    // -- Drawer create playlist
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [loadingDrawer, setLoadingDrawer] = React.useState(false);

    // -- Drawer update playlist
    const [openDrawerUpdate, setOpenDrawerUpdate] = React.useState(false);
    const [loadingDrawerUpdate, setLoadingDrawerUpdate] = React.useState(false);
    const [pathGroupId, setPathGroupId] = React.useState(0);
    
    // -- Filters
    const [statusData] = React.useState([
        { id: PLAYLIST_ESTATUS_PUBLISH, label: "Publicado" },
        { id: PLAYLIST_ESTATUS_UNPUBLISH, label: "Borrador" },
    ]);
    const [clients, setClients] = React.useState([]);
    const [filterName, setFilterName] = React.useState("");
    const [filterClient, setFilterClient] = React.useState("");
    const [filterStatus, setFilterStatus] = React.useState("");

    // -- Alert
    const [alertProps, setAlertProps] = React.useState({
        open: false,
        severity: "success",
        duration: 4000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        message: "",
    });

    // -- Modal
    const [modalProps, setModalProps] = React.useState({
        iconClassname: "",
        message: ""
    });
    const [openModal, setOpenModal] = React.useState(false);

    // -- Desarchivar
    const [pathGroupIdToUnarchive, setPathGroupIdToUnarchive] = React.useState(0);

    // FUNCTIONS
    const getPlaylist = React.useCallback(async (newPage = 1, filtersToGet) => {
        try {
            setLoadingTable(true);
            const options = { page: newPage, rows: TOTAL_ROWS_TO_SEARCH_TABLE};
            if (!!filtersToGet.client) options.companyId = filtersToGet.client;
            if (!!filtersToGet.name) options.text = filtersToGet.name;
            if (!!filtersToGet.status) options.statusId = filtersToGet.status;

            const response = await AcademiesServiceSDK.getPathsList(options);

            if (!!response.success) {
                setDataPlaylists(response.data.playlists);
                setTotalRows(response.data.totalRows);
            } else {

            }

        } catch (error) {

        }
        setLoadingTable(false);
    }, []);

    const changePlaylistStatus = React.useCallback(async (isActive, statusId, pathGroupId) => {
        try {
            setLoadingTable(true);
            const body = {
                isActive,
                statusId,
                pathGroupId
            };
            const response = await AcademiesServiceSDK.updatePathStatus(body);

            if (!!response.success) {
                if (tabsIndexSelected === 0)
                    getPlaylist(page, {name: filterName, client: filterClient, status: filterStatus});
                else 
                    getPlaylist(page, { status: PLAYLIST_ESTATUS_ARCHIVED});
            }
        } catch (error) {

        }
    }, [getPlaylist, tabsIndexSelected, filterName, filterClient, filterStatus]);

    //HANDLES 
    // -- Actions tables
    const handleChangePagination = (event, value) => {
        setPage(value);
        getPlaylist(value, {name: filterName, companyId: filterClient, status: filterStatus});
    };
    // -- Evento para el cambio de tabs
    const handleChangeTabIndex = (index) => {
        setTabsIndexSelected(index);
        setPage(1);
        setFilterName("");
        setFilterClient("");
        setFilterStatus(index === 0 ? "" : PLAYLIST_ESTATUS_ARCHIVED);
        getPlaylist(1, {status: index === 0 ? "" : PLAYLIST_ESTATUS_ARCHIVED})
    };
    // -- Evento para la seleccion del cliente.
    const handleChangeSelectClient = (value) => {
        setFilterClient(value);
        getPlaylist(1, {name: filterName, client: value, status: filterStatus});
    };
    // -- Evento para la seleccion de estatus.
    const handleChangeSelectStatus = (value) => {
        setFilterStatus(value);
        getPlaylist(1, {name: filterName, client: filterClient, status: value});
    };
    // -- Evento para cambiar el texto del filtro de busqueda por nombre.
    const handleChangeFilterTextValue = (value) => {
        setFilterName(value);
    };
    // -- Evento para eliminar todo el texto del filtro de busqueda por nombre.
    const handleClickDeleteFilterTextValue = () => {
        setFilterName("");
        getPlaylist(1, {name: "", client: filterClient, status: filterStatus});
    };
    // -- Evento para cuando presionen enter en el campo de texto de los filtros.
    const handleKeyDownFilterTextValue = (text) => {
        getPlaylist(1, {name: text, client: filterClient, status: filterStatus});
    };
    // -- Drawer create playlist
    const handleChangeOpenDrawer = (data) => {
        setOpenDrawer(data);
    };
    const handleChangeLoadingDrawer = () => {
        setLoadingDrawer(prevState => !prevState);
    };

    // -- Drawer update playlist
    const handleChangeOpenDrawerUpdate = (data) => {
        if (!data) setLoadingDrawerUpdate(false);
        setOpenDrawerUpdate(prevState => data ?? !prevState);
    };
    const handleChangeLoadingDrawerUpdate = () => {
        setLoadingDrawerUpdate(prevState => !prevState);
    };
    // -- Evento para cerrar los toast.
    const hanldeFinishedToast = () => {
        setAlertProps(prevState => ({ ...prevState, open: false, message: "", }));
    };

    // -- Evento para recargar la tabla
    const handleReloadTable = () => {
        getPlaylist(1, {name: filterName, client: filterClient, status: filterStatus});
    };

    // -- Evento para confirmar el boton del modal
    const handleButtonConfirmModal = () => {
        changePlaylistStatus(0, PLAYLIST_ESTATUS_UNPUBLISH, pathGroupIdToUnarchive);
        setPathGroupIdToUnarchive(0);
        setOpenModal(false);
    };

    // -- Evento para confirmar el boton del modal
    const handleButtonCancelModal = () => {
        setPathGroupIdToUnarchive(0);
        setOpenModal(false);
    };
    // -- Evento para cerrar el modal
    const handleCloseModal = async () => {
        try {
            setOpenModal(false);
        } catch (error) {
            setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: error.message }));
        }
    };

    // EFFECTS
    React.useEffect(() => {
        const getClients = async () => {
            try {
                const response = await AcademiesServiceSDK.getClientOrGroup({ type: "clients" }, ac);

                if (!!response.success) {
                    setClients(response.data.clients.map(client => ({ id: client.company_id, label: client.name })));
                } else {
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                }
            } catch (error) {
                if (error.name === 'AbortError')
                    console.log('Error\n', error.message);
                else
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };
        getClients();
    }, []);

    React.useEffect(() => {
        getPlaylist(1, {});
    }, [getPlaylist]);
    
    React.useEffect(() => {
        // -- Evento para duplicar la playlist.
        const handleClickDuplicate = async id => {
            try {
                setLoadingTable(true);
                const copyPlaylist = await AcademiesServiceSDK.copyPlaylist({pathGroupId: id});

                if (!copyPlaylist.success) throw new Error(copyPlaylist.error ?? "Error al generar la playlist.");

                getPlaylist(page, {name: filterName, client: filterClient, status: filterStatus});
                setPathGroupId(copyPlaylist.data.pathGroupId);
                setOpenDrawerUpdate(true);
                setLoadingDrawerUpdate(true);
            } catch (error)  {
                setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            };
            setLoadingTable(false);
        };
        // -- Evento que borra la playlist
        const handleClickUnpublish = id => {
            changePlaylistStatus(0, PLAYLIST_ESTATUS_UNPUBLISH, id);
        };
        // -- Evento que desarchiva la playlist
        const handleClickUnarchive = ( id, name ) => {
            setModalProps(() => ({ iconClassname: "ri-save-2-fill text-dark-black-200", message: `¿Deseas desarchivar “${name}”? La playlist se guardará como borrador.` }));
            setOpenModal(true);
            setPathGroupIdToUnarchive(id);
        };
        // -- Evento que abre la seccion para editar una playlist.
        const handleClickEdit = id => {
            setOpenDrawerUpdate(true);
            setLoadingDrawerUpdate(true);
            setPathGroupId(id);
        };
        // -- Evento para archivar playlists.
        const handleClickArchive = id => {
            changePlaylistStatus(0, PLAYLIST_ESTATUS_ARCHIVED, id);
        };

        const data = dataPlaylists.map(playlist => ({
            pathGroupId: playlist.path_group_id,
            namePlaylist: playlist.name,
            client: playlist.company_name,
            students: playlist.num_alumnos,
            subjects: playlist.num_materias,
            update: dateFormat(playlist.last_updated, DD_MMM_YYYY_FORMAT),
            create: dateFormat(playlist.create_at, DD_MMM_YYYY_FORMAT),
            statusId: playlist.status_id,
            actions: ''
        }));

        const playlists = data.map((item, index) => {
            item.name = <Box display='flex' alignItems='center' gridGap={10}>
                <Icon className='ri-play-list-2-line text-dark-black-200' style={{ height: 20 }} />
                <Typography variant='body1' className='semi-bold'>{item.namePlaylist}</Typography>
            </Box>;
            item.status = <ItemStatusTable statusId={item.statusId} />
            item.id = index;
            let options = []; 
            switch (item.statusId) {
                case 1:
                    options = [
                        { id: 1, label: 'Despublicar', fun: () => handleClickUnpublish(item.pathGroupId) },
                        { id: 2, label: 'Duplicar', fun: () => handleClickDuplicate(item.pathGroupId) },
                        { id: 3, label: 'Editar', fun: () => handleClickEdit(item.pathGroupId) },
                        { id: 4, label: 'Archivar', fun: () => handleClickArchive(item.pathGroupId) },
                    ];
                    break;
                case 2: 
                    options = [
                        { id: 1, label: 'Duplicar', fun: () => handleClickDuplicate(item.pathGroupId) },
                        { id: 2, label: 'Editar', fun: () => handleClickEdit(item.pathGroupId) },
                        { id: 3, label: 'Archivar', fun: () => handleClickArchive(item.pathGroupId) },
                    ];
                    break;
                case 3:
                    options = [
                        { id: 1, label: 'Desarchivar', fun: () => handleClickUnarchive(item.pathGroupId, item.namePlaylist) },
                        { id: 2, label: 'Editar', fun: () => handleClickEdit(item.pathGroupId) },
                    ];
                    break;
                default: 
                    options = [];
            }

            return {
                ...item,
                actions: <MoreOptions options={options} iconButtonProps={{ className: 'p-0 disable-hover' }} />
            }
        });
        setRows(playlists);
    }, [dataPlaylists]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box display='flex' justifyContent='space-between'>
                                <Box>
                                    <Typography variant='h3' className='mb-2'>Gestión de Academy</Typography>
                                    <Typography variant='body2' className='text-dark-black-200'>Crea y gestiona las Playlist Academy para distintos clientes.</Typography>
                                </Box>
                                <Box>
                                    <DrawerButtonContainer
                                        title="Crear playlist Academy"
                                        textBttn={"Crear Playlist Academy"}
                                        endIcon={<Icon className='ri-menu-add-fill' />}
                                        open={openDrawer}
                                        onClickButton={handleChangeOpenDrawer}
                                        loading={loadingDrawer}
                                    >
                                        <FormPlaylist onChangeLoadingDrawer={handleChangeLoadingDrawer} onChangeDrawer={handleChangeOpenDrawer} onReloadTable={handleReloadTable} />
                                    </DrawerButtonContainer>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        onChange={handleChangeTabIndex}
                        activeTab={tabsIndexSelected}
                        tabs={tabs}
                    >
                        <Card>
                            <LoaderContent loading={loadingTable}>
                                <TableFilter>
                                    <Grid item xs={!tabsIndexSelected ? 6 : 12}>
                                        <InputSearch
                                            value={filterName}
                                            onKeyDown={handleKeyDownFilterTextValue}
                                            onChange={handleChangeFilterTextValue}
                                            onClickDelete={handleClickDeleteFilterTextValue}
                                            placeholder={"Buscar playlists"}
                                        />
                                    </Grid>
                                    {
                                        !tabsIndexSelected &&
                                        <>
                                        <Grid item xs={3}>
                                            <Select
                                                label="Clientes"
                                                name="client"
                                                items={clients}
                                                value={filterClient}
                                                onChange={e => handleChangeSelectClient(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Select
                                                label="Estatus"
                                                name="status"
                                                items={statusData}
                                                value={filterStatus}
                                                onChange={e => handleChangeSelectStatus(e.target.value)}
                                            />
                                        </Grid>
                                        </>
                                    }
                                </TableFilter>
                                <Table
                                    columns={[
                                        { name: 'name', label: 'Nombre de playlist' },
                                        { name: 'client', label: 'Cliente', align: 'center' },
                                        { name: 'students', label: 'Alumnos' },
                                        { name: 'subjects', label: 'Materias' },
                                        { name: 'update', label: 'Fecha de última actualización' },
                                        { name: 'create', label: 'Fecha de creación' },
                                        { name: 'status', label: 'Estatus' },
                                        { name: 'actions', label: 'Acciones', align: 'right' },
                                    ]}
                                    rows={rows}
                                    loading={false}
                                    className={classes.table}
                                />
                                <Pagination
                                    results={rows.length}
                                    totalResults={totalRows}
                                    page={page}
                                    count={!!totalRows && !!rows.length ? Math.ceil(totalRows / TOTAL_ROWS_TO_SEARCH_TABLE) : 1}
                                    onChange={handleChangePagination}
                                />
                            </LoaderContent>
                        </Card>
                    </Tabs>
                </Grid>
            </Grid>

            <EdithPlaylist
                loading={loadingDrawerUpdate}
                title="Editar playlist"
                open={openDrawerUpdate}
                onChangeLoadingDrawer={handleChangeLoadingDrawerUpdate}
                onChangeDrawer={handleChangeOpenDrawerUpdate}
                onReloadTable={handleReloadTable}
                pathGroupId={pathGroupId} />

            <Toast {...alertProps}
                onFinished={hanldeFinishedToast}
            />

            <ModalToConfirm modalProps={modalProps} onClickConfirmButton={handleButtonConfirmModal} onClickCancelButton={handleButtonCancelModal} onCloseModal={handleCloseModal} openModal={openModal}/>
        </>
    );
};

export default Academy;