import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';

// Material components
import { Box, Button, Chip, TextField, Typography, makeStyles } from '@material-ui/core';

// Shared components
import Select from '../../../design-system/components/Form/Select/Select';
import Toast from '../../../design-system/components/Toast/Toast';

import UserList from './UserList/UserList';
import ModalToConfirm from '../ModalToConfirm/ModalToConfirm';

// @SDK
import { GroupService } from '@sdk-point/talisis';
const GroupServiceSDK = new GroupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.colors.gray[50]
        }
    },
    boxContainer: {
        padding: "8px 24px 70px 24px"
    },
    boxActions: {
        position: 'fixed',
        bottom: 0,
        flexDirection: 'row',
        display: 'flex',
        width: 598,
        backgroundColor: theme.palette.colors.gray[200],
        zIndex: 1
    },
    boxButtonActions: {
        display: 'flex',
        padding: '24px',
        gap: '8px',
        width: '100%'
    },
}));

const FormGroup = ({ onChangeDrawer, onChangeLoadingDrawer, tagGroupModuleId, onReloadTable, isDuplicatingGroup, user }) => {
    const classes = useStyles();
    const ac = new AbortController();

    // CONSTANTS
    const INI_FORM = {
        companyId: "",
        membershipId: "",
        name: "",
        email: "",
    };

    // STATES
    // Form
    const [form, setForm] = React.useState({ ...INI_FORM });
    const [clients, setClients] = React.useState([]);
    const [memberships, setMemberships] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [tagsInUse, setTagsInUse] = React.useState([]);
    const [emailsInUse, setEmailsInUse] = React.useState([]);
    const [group, setGroup] = React.useState("");
    const [filterUser, setFilterUser] = React.useState("");
    const [users, setUsers] = React.useState([]);
    const [excludedUsers, setExcludedUsers] = React.useState([]);
    const [selectsUpdatedWithGroup, setSelectsUpdatedWithGroup] = React.useState(false);
    
    const [alertProps, setAlertProps] = React.useState({
        open: false,
        severity: "success",
        duration: 4000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        message: "",
    });
    const [modalProps, setModalProps] = React.useState({
        iconClassname: "",
        message: ""
    });
    const [openModal, setOpenModal] = React.useState(false);

    // FUNCTIONS
    const validateEmail = React.useCallback((email, emails = []) => {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!email.length)
            return {valid: false, message: "Favor de agregar un correo."};
        else if (!pattern.test(email))
            return {valid: false, message: "Correo no valido."};
        else if (!!emails.includes(email))
            return {valid: false, message: "Correo ya registrado."};
        else    
            return {valid: true, message: ""};
    }, []);

    // HANDLES
    // -- Evento para cambiar alguen dato del formulario.
    const handleChange = ({ name, value }) => {
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // -- Evento para cerrar los toast.
    const hanldeFinishedToast = () => {
        try {
            setAlertProps(prevState => ({ ...prevState, open: false, message: "", }));
        } catch (error) {
        }
    };

    // -- Evento al seleccionar un tag del listado.
    const handleSelectedTag = (e) => {
        try {
            setTagsInUse(prevState => ([...prevState, {id: e.target.value, name: e.nativeEvent.target.textContent}]));
        } catch (error) {}
    };

    // -- Evento al seleccionar un tag del listado.
    const handleClickChipTagDelete = (id) => {
        try {
            setTagsInUse(prevState => prevState.filter(item => item.id !== id));
        } catch (error) {}
    };
    
    // -- Evento al eliminar un correo al listado.
    const handleClickChipEmailDelete = (email) => {
        try {
            setEmailsInUse(prevState => prevState.filter(item => item !== email));
        } catch (error) {}
    };
    
    // -- Evento para exluir un usuario 
    const handleCheckExcludedPerson = (personId) => {
        try {
            setExcludedUsers(prevState => {
                if (prevState.includes(personId)) 
                    return prevState.filter(item => item !== personId);
                else 
                    return [...prevState, personId];
            });
            setUsers(prevState => {
                const newState = prevState.map(item => {
                    if (item.person_id === personId)
                        item.checkedExcluded = !item.checkedExcluded;
                    return item;
                });

                return newState;
            });
        } catch (error) {}
    };
    
    // -- Evento al agregar un correo al listado.
    const handleClickUseEmail = () => {
        try {
            const validEmail = validateEmail(form.email, emailsInUse);
            
            if (validEmail.valid) {
                setEmailsInUse(prevState => ([...prevState, form.email]));
                handleChange({name: "email", value: ""});
            }
            else 
                setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: validEmail.message }));
        } catch (error) {}
    };
    
    // -- Evento al cambiar el texto del filtro de usuario.
    const handleChangeFilterUser = (text) => {
        try {
            setFilterUser(text);
        } catch (error) {}
    };
    
    // -- Evento para crear un grupo.
    const handleClickCreateGroup = async () => {
        try {
            onChangeLoadingDrawer();
            const body = {
                personId: user.person_id,
                membershipId: form.membershipId,
                companyId: form.companyId,
                name: form.name,
                tags: tagsInUse.map(item => item.id),
                extraUsers: users.filter(item => !!item.isExtraUser && !!item.checkedExcluded && !excludedUsers.includes(excludedUsers)).map(item => item.person_id),
                excludedUsers: excludedUsers,
            };
            
            const response = (!!tagGroupModuleId && !isDuplicatingGroup) ? await GroupServiceSDK.updateGroup(tagGroupModuleId, body) : await GroupServiceSDK.createGroup(body, ac);

            if (!!response.success) {
                setModalProps(() => ({ iconClassname: "ri-checkbox-circle-fill text-electric-green-300", message: `El grupo “${form.name}” ha sido publicado con éxito.` }));
                onReloadTable();
                setOpenModal(true);
            } else {
                setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
            }
        } catch (error) {
            if (error.name === 'AbortError')
                console.log('Error\n', error.message);
            else
                setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
        }
        onChangeLoadingDrawer();
    };

    // -- Evento para hacer submit del modal.
    const handleButtonModal = (type) => {
        onChangeDrawer(false);
        setForm({ ...INI_FORM });
        setOpenModal(false);
    };

    // -- Evento para cerrar el modal.
    const handleCloseModal = async () => {
        try {
            setOpenModal(false);
        } catch (error) {
            setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: error.message }));
        }
    };

    // EFFECTS
    React.useEffect(() => {
        const getClients = async () => {
            try {
                const response = await GroupServiceSDK.getClientsOrMemberships({ type: "clients" }, ac);

                if (!!response.success) {
                    setClients(response.data.clients.map(client => ({ id: client.company_id, label: client.name })));
                } else {
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                }
            } catch (error) {
                if (error.name === 'AbortError')
                    console.log('Error\n', error.message);
                else
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };

        const getTags = async () => {
            try {
                const response = await GroupServiceSDK.getTags(ac);

                if (!!response.success) {
                    setTags(response.data.tags.map(tag => ({ id: tag.tag_id, label: tag.name })));
                } else {
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                }
            } catch (error) {
                if (error.name === 'AbortError')
                    console.log('Error\n', error.message);
                else
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };

        const getGroup = async () => {
            try {
                const response = await GroupServiceSDK.getGroup(tagGroupModuleId, ac);

                if (!!response.success) {
                    const group = response.data.group;
                    setGroup(group);
                    setForm(prevState => ({
                        ...prevState,
                        companyId: group.company_id,
                        membershipId: group.membership_id,
                        name: group.name + (isDuplicatingGroup ? " " + moment().format('DD/MMMM/YYYY HH:mm:ss') : ""),
                    }));
                    setExcludedUsers(group.excluded_users.map(item => item.person_id));
                    setTagsInUse(group.tags.map(item => ({id: item.tag_id, name: item.tag_name})));
                    setEmailsInUse(group.extra_users.map(item => item.email));
                } else {
                    setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: response.error }));
                }
            } catch (error) {
                setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: error.message }));
            }
        };

        const getData = async () => {
            onChangeLoadingDrawer();
            if(!!tagGroupModuleId) await getGroup();
            await getClients();
            await getTags();
            onChangeLoadingDrawer();
        };

        getData();
        return () => ac.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const getMemberships = async () => {
            try {
                if (!!form.companyId) {
                    handleChange({ name: "membershipId", value: "" });
                    const response = await GroupServiceSDK.getClientsOrMemberships({ type: "memberships", companyId: form.companyId }, ac);
                    if (!!response.success) {
                        setMemberships(response.data.memberships.map(membership => ({ id: membership.membership_id, label: membership.name })));
                    } else {
                        setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                    }
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    handleChange({ name: "membershipId", value: null });
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
                } else
                    console.log('Error\n', error.message);
            }
        };
        getMemberships();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ form.companyId ]);

    React.useEffect(() => {
        const setClientForm = () => {
            try {
                if (!!clients && !!clients.length && !!group && !!Object.keys(group).length && !selectsUpdatedWithGroup) {
                    handleChange({ name: "companyId", value: group.company_id });
                }
                if (!!memberships && !!memberships.length && !!group && !!Object.keys(group).length && !selectsUpdatedWithGroup) {
                    handleChange({ name: "membershipId", value: group.membership_id });
                    setSelectsUpdatedWithGroup(true);
                }
            } catch (error) {
                setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };
        setClientForm();
    }, [clients, group, memberships]);

    React.useEffect(() => {
        const getUsers = async () => {
            try {
                if (!!tagsInUse && !tagsInUse.length && !!emailsInUse && !emailsInUse.length) {
                    setUsers([]);
                    return;
                }
                onChangeLoadingDrawer();

                const response = await GroupServiceSDK.getUsersForAdmin({tags: tagsInUse.map(tagInUse => tagInUse.id), emails: emailsInUse}, ac);

                if (!!response.success) {
                    const tempUsers = response.data.users;
                    setUsers(tempUsers.map(item => ({...item, checkedExcluded: !excludedUsers.includes(item.person_id)})));
                    setExcludedUsers(prevState => prevState.filter(u => !!tempUsers.some(tu => tu.person_id === u)));
                } else {
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                }
                onChangeLoadingDrawer();
            } catch (error) {
                if (error.name === 'AbortError')
                    console.log('Error\n', error.message);
                else
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };
        getUsers();
    }, [tagsInUse, emailsInUse]);


    return (
        <>
            <Box className={clsx(classes.boxContainer)} display="flex" flexDirection="column" gridGap="24px">
                <Box>
                    <Select
                        label="Cliente"
                        onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                        value={form.companyId}
                        name={"companyId"}
                        items={clients}
                    />
                </Box>
                <Box>
                    <Select
                        label="Membresía"
                        onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                        value={form.membershipId}
                        name={"membershipId"}
                        items={memberships}
                    />
                </Box>
                <Box>
                    <TextField
                        value={form.name}
                        placeholder='Nombre del grupo'
                        name="name"
                        variant="outlined"
                        label="Nombre del grupo"
                        fullWidth
                        onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>Añadir usuarios</Typography>
                </Box>
                <Box>
                    <Box display="flex" flexDirection="column" gridGap="8px">
                        <Box display="flex" flexDirection="row" gridGap="8px">
                            <Select
                                label="Tags"
                                onChange={handleSelectedTag}
                                value={""}
                                items={tags.filter(tag => !tagsInUse.some(selectedTag => selectedTag.id === tag.id))}
                                />
                        </Box>
                        <Box display="flex" flexDirection="row" gridGap="8px" flexWrap="wrap">
                            {
                                tagsInUse.map(tagInUse => 
                                    <Chip
                                        key={tagInUse.id}
                                        label={tagInUse.name}
                                        onDelete={() => handleClickChipTagDelete(tagInUse.id)}
                                        variant="outlined"
                                    />
                                )
                            }
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box display="flex" flexDirection="column" gridGap="8px">
                        <Box display="flex" flexDirection="row" gridGap="8px">
                            <TextField
                                value={form.email}
                                placeholder='Escribe el correo electrónico'
                                name="email"
                                variant="outlined"
                                label="Añadir usuarios individuales por correo electrónico"
                                fullWidth
                                onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                                />
                            <Box display="flex" alignItems="flex-end">
                                <Button variant='contained' onClick={handleClickUseEmail}>Añadir</Button>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" gridGap="8px" flexWrap="wrap">
                            {
                                emailsInUse.map(emailInUse => 
                                    <Chip
                                        key={emailInUse}
                                        label={emailInUse}
                                        onDelete={() => handleClickChipEmailDelete(emailInUse)}
                                        variant="outlined"
                                    />
                                )
                            }
                        </Box>
                    </Box>
                </Box>
                <UserList 
                    users={users.filter(item => {
                        if (!filterUser.length) return true;

                        return (item.email.includes(filterUser) || item.person_name.includes(filterUser) || item.tags.includes(filterUser));
                    })} 
                    onCheckExcludedPerson={handleCheckExcludedPerson} 
                    filterUser={filterUser} 
                    onChangeFilterUser={handleChangeFilterUser}/>
            </Box>
            <Box className={classes.boxActions}>
                <Box className={classes.boxButtonActions}>
                    <Button variant='contained' fullWidth onClick={() => onChangeDrawer(false)}>Descartar</Button>
                    <Button variant='contained' color='primary' fullWidth disabled={!form.companyId || !form.membershipId || !form.name} onClick={handleClickCreateGroup}>Guardar grupo</Button>
                </Box>
            </Box>
            <Toast {...alertProps}
                onFinished={hanldeFinishedToast}
            />

            <ModalToConfirm modalProps={modalProps} onClickConfirmButton={handleButtonModal} onCloseModal={handleCloseModal} openModal={openModal}/>
        </>
    );
};

const mapStateToProps = ({ userReducer }) => ({
	...userReducer
});
export default connect(mapStateToProps, null)(FormGroup);