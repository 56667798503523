import React from 'react';

import { usePrefersTheme, DARK_THEME, LIGHT_THEME } from '../../../theme';

import { Box, Typography} from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    [DARK_THEME]:{
        '& .info-pagination':{
            color: theme.palette.colors.fillStroke[100]
        }
    },
    [LIGHT_THEME]:{
        '& .info-pagination':{
            color: theme.palette.colors.darkBlack[300]
        }
    }
}));

const Pagination = ({results, totalResults, ...restPaginationProps}) => {
    const classes = useStyles();

    const { prefersTheme } = usePrefersTheme();

    return (
        <Box display='flex' py={1.5} px={3} justifyContent='space-between' alignItems='center' className={classes[prefersTheme]}>
            <Box className='title'>
                <Typography variant="caption" className='info-pagination'>Mostrando {results} resultados de un total de {totalResults}</Typography>
            </Box>
            <MuiPagination shape="rounded" {...restPaginationProps}/>
        </Box>
    );
};

export default Pagination;