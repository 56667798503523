import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import useStyles from '../../../assets/styles/components/fileDetail/fileDetailStyle';

// Shared constants
import { 
    APPROVED_STATUS, 
    PENDING_STATUS, 
    REJECTED_STATUS, 
    REVIEW_STATUS, 
    SEND_TO_REVIEW_PHYSICALLY_STATUS 
} from './status';

// Material components
import { 
    Box, 
    Typography,
    Button,
    Icon,
    Chip,
    Tooltip
} from '@material-ui/core';

const statusIcon = ({
    admin: {
        [PENDING_STATUS]: 'ri-time-line text-gray-100',
        [APPROVED_STATUS]: 'ri-checkbox-circle-fill text-electric-green-300',
    }, 
    user: {
        [PENDING_STATUS]: 'ri-time-line text-blue-300',
        [SEND_TO_REVIEW_PHYSICALLY_STATUS]: 'ri-time-line text-blue-300',
        [APPROVED_STATUS]: 'ri-checkbox-circle-fill text-electric-green-300',
        [REVIEW_STATUS]: 'ri-checkbox-circle-fill text-blue-300',
        [REJECTED_STATUS]: 'ri-error-warning-line text-electric-red-300',
    } 
});

const SHOW_ICON_FOR_USER_BY_STATUS = [REVIEW_STATUS, REJECTED_STATUS, APPROVED_STATUS, SEND_TO_REVIEW_PHYSICALLY_STATUS];
const SHOW_ICON_FOR_ADMIN_BY_STATUS = [PENDING_STATUS, REJECTED_STATUS, APPROVED_STATUS];

// const useStyles = makeStyles((theme) => ({
//     chip:{
//         ...theme.typography.bodyTextXsmallRegular,
//         padding: '4px 16px',
//         maxWidth: 192,
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//         '& .MuiChip-label':{
//             padding: 0
//         }
//     },
//     root:{
//         '& .icon-file':{
//             fontSize: 40
//         },
//     },
//     [theme.breakpoints.down('xs')]:{
//         root:{
//             gridGap: 16,
//             width: '100%',
//             '& .icon-file':{
//                 fontSize: 26
//             }
//         },
//         document:{
//             flexDirection: 'column',
//             alignItems: 'flex-start',
//             gap: 4
//         },
//         fileDetail:{
//             '&.inline':{
//                 maxWidth: 180
//             }
//         },
//         chip:{
//             height: 20,
//             padding: '2px 8px',
//             maxWidth: 140,
//             '& .MuiChip-label':{
//                 fontSize: '10px !important'
//             }
//         },
//     },
//     tooltipContainer: {
//         backgroundColor: "#C4C4C4 !important",
//         color: `${theme.palette.colors.fillStroke[400]} !important`,
//     },
//     tooltipArrowContainer: {
//         color: "#C4C4C4 !important",
//     }
// }));

const FileDetail = ({active = false, title, fileName, filePath, dueDate, status, tags, isAdmin, downloadableFile, instruction, messageToUser, approvedDate, displayFileIcon, dueDateText, ...rest}) => {
    const classes = useStyles();

    return (
        <>        
            <Box display='flex' alignItems='center' gridGap={38} {...rest} className={classes.root}>
                {
                    displayFileIcon &&
                    <Icon
                        className={clsx('ri-file-text-line icon-file',
                        {
                            [classes.iconFileColor]: !active,
                            'text-error-300': active && (status === REJECTED_STATUS && !isAdmin),
                            'text-violet-300': active && !(status === REJECTED_STATUS && !isAdmin)
                        })}
                        // style={{color: active 
                        //     ? (status === REJECTED_STATUS && !isAdmin ? '#F74F58' : '#9F6FFB') 
                        //     : '#A0A9BA'}}
                    />
                }
                <Box display='flex' flexDirection='column' gridGap={8} className={clsx(classes.fileDetail)}>
                    <Box display="flex" gridGap={8} alignItems="center"> 
                        <Typography variant='body1' className={classes.title}>{title}</Typography>
                        {
                            !!instruction &&
                            <Tooltip title={instruction} arrow placement="top" interactive classes={{tooltip: classes.tooltipContainer, arrow: classes.tooltipArrowContainer}}>
                                <Icon className={'ri-information-line'} style={{fontSize: 16}} />
                            </Tooltip>
                        }
                    </Box>
                    {
                        !!downloadableFile && !isAdmin && status === PENDING_STATUS &&  
                        <Box display="flex">
                            <Button color='primary' endIcon={<Icon className='ri-download-2-line' />} href={downloadableFile} className='p-0'>Descargar</Button>
                        </Box>
                    }
                    {
                        active &&
                        <Box display='flex' alignItems='center' className={classes.document}>
                            <Typography variant='body2' className='text-fill-stroke-100'>{fileName}</Typography>
                            <Typography variant='body2' component='span' className='text-fill-stroke-100 d-flex d-sm-none mx-1'>-</Typography>
                            {
                                (!!isAdmin || (!isAdmin && [REJECTED_STATUS, REVIEW_STATUS].includes(status))) && 
                                <Button color='primary' className='p-0' disableRipple href={filePath}>Ver documento</Button>
                            }
                            {
                                !isAdmin && status === APPROVED_STATUS &&
                                <Typography variant='body2' className='text-fill-stroke-100'>{approvedDate}</Typography>
                            }
                        </Box>
                    }
                    { 
                        !!dueDate &&
                        <Typography variant='body2' className='text-fill-stroke-100'>{dueDateText} {dueDate}</Typography>
                    }
                    {
                        !!tags && !!tags.length &&
                        <Box display="inline">
                            {
                                tags.map((tag, index) => 
                                        <Chip className={clsx('chip-outlined-purple', classes.chip, (index > 0) && 'offset-ml-2')}
                                            label={tag.name || tag}
                                        />
                                )
                            }
                        </Box>
                    }
                </Box>
            </Box>
            {
                (!!isAdmin && SHOW_ICON_FOR_ADMIN_BY_STATUS.includes(status) || (!isAdmin && SHOW_ICON_FOR_USER_BY_STATUS.includes(status)) ) &&
                <Box display="flex">
                    {
                        !isAdmin && [REVIEW_STATUS, SEND_TO_REVIEW_PHYSICALLY_STATUS].some(reviewStatus =>  status === reviewStatus) &&
                        <Typography variant='body1' className={clsx('text-gray-100', 'offset-mr-3')}>Enviado a revisión</Typography>
                    }

                    <Tooltip title={messageToUser} arrow placement="top" interactive classes={{tooltip: classes.tooltipContainer, arrow: classes.tooltipArrowContainer}}>
                        <Icon className={statusIcon[!!isAdmin ? 'admin' : 'user'][status]} style={{fontSize: 24}} />
                    </Tooltip>
                </Box>
            }
        </>
    );
};

FileDetail.defaultProps = {
    isAdmin: true,
    messageToUser: "",
    displayFileIcon: true,
    dueDateText: 'Fecha límite:'
};

export default FileDetail;