import * as userTypes from '../types/userTypes';
import * as Session from '../../pages/Login/Session/Session';

export const applyUser = (user) => (dispatch) => {
    Session.setSessionCookie(user);
    dispatch({
        type: userTypes.APPLY_USER,
        payload: user
    });
};

export const updateUserProperty = (propName, value) => {
    return (dispatch, getState) => {
        const { user } = getState().userReducer;
        const newUser = {
            ...user,
            [propName]: value,
        };
        Session.setSessionCookie(newUser);
        dispatch(applyUser(newUser));
    };
};
