import React from 'react';

// Material components
import { Typography, Card, Grid, CardContent, Box, Icon, makeStyles } from '@material-ui/core';

// Shared components
import Table from '../../design-system/components/Table/Table';
import Pagination from '../../design-system/components/Table/components/Pagination';
import MoreOptions from '../../design-system/components/MoreOptions/MoreOptions';
import TableFilter from '../../design-system/components/Table/TableFilter/TableFilter';
import InputSearch from '../../design-system/components/Form/InputSearch/InputSearch';
import Select from '../../design-system/components/Form/Select/Select';
import DrawerButtonContainer from '../../design-system/components/Drawer/DrawerButtonContainer';
import LoaderContent from '../../design-system/components/Loader/LoaderContent';
import Toast from '../../design-system/components/Toast/Toast';

// Components
import FormGroup from './FormGroup/FormGroup';
import EdithGroup from './EdithGroup/EdithGroup';
import ModalToConfirm from './ModalToConfirm/ModalToConfirm';

// @SDK
import { GroupService } from '@sdk-point/talisis';
const GroupServiceSDK = new GroupService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

// Constants
const TOTAL_ROWS_TO_SEARCH_TABLE = 10;

const useStyles = makeStyles((theme) => ({
    table:{
        [theme.breakpoints.down('lg')]:{
            maxHeight: '500px !important'
        },
        [theme.breakpoints.up('xl')]:{
            maxHeight: '500px !important'
        }
    },
}))

const Group = () => {
    const ac = new AbortController();
    const classes = useStyles();
    // STATES
    // -- Table
    // const [dataPlaylists, setDataPlaylists] = React.useState([]);
    const [dataGroups, setDataGroups] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [rows, setRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
    const [loadingTable, setLoadingTable] = React.useState(false);
    
    // -- Drawer create playlist
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [loadingDrawer, setLoadingDrawer] = React.useState(false);

    // -- Drawer update playlist
    const [openDrawerUpdate, setOpenDrawerUpdate] = React.useState(false);
    const [loadingDrawerUpdate, setLoadingDrawerUpdate] = React.useState(false);
    const [tagGroupModuleId, setTagGroupModuleId] = React.useState(0);
    
    // -- Filters
    // const [statusData] = React.useState([
    //     { id: PLAYLIST_ESTATUS_PUBLISH, label: "Publicado" },
    //     { id: PLAYLIST_ESTATUS_UNPUBLISH, label: "Borrador" },
    // ]);
    const [clients, setClients] = React.useState([]);
    const [memberships, setMemberships] = React.useState([]);
    const [filterName, setFilterName] = React.useState("");
    const [filterClient, setFilterClient] = React.useState("");
    const [filterMembership, setFilterMembership] = React.useState("");

    // -- Alert
    const [alertProps, setAlertProps] = React.useState({
        open: false,
        severity: "success",
        duration: 4000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        message: "",
    });

    // -- Modal
    const [modalProps, setModalProps] = React.useState({
        iconClassname: "",
        message: ""
    });
    const [openModal, setOpenModal] = React.useState(false);

    // -- Deleting group
    const [tagGroupModuleIdToDelete, setTagGroupModuleIdToDelete] = React.useState(0);
    
    // Diplicating group
    const [isDuplicatingGroup, setIsDuplicatingGroup] = React.useState(false);

    // FUNCTIONS
    const getGroups = React.useCallback(async (newPage = 1, filtersToGet) => {
        try {
            setLoadingTable(true);
            const options = { page: newPage, rows: TOTAL_ROWS_TO_SEARCH_TABLE};
            if (!!filtersToGet.client) options.companyId = filtersToGet.client;
            if (!!filtersToGet.name) options.text = filtersToGet.name;
            if (!!filtersToGet.membership) options.membershipId = filtersToGet.membership;

            const response = await GroupServiceSDK.getGroups(options);

            if (!!response.success) {
                setDataGroups(response.data.groups);
                setTotalRows(response.data.totalRows);
            } else {

            }

        } catch (error) {

        }
        setLoadingTable(false);
    }, []);

    const changeGroupStatus = React.useCallback(async (isActive, id) => {
        try {
            setLoadingTable(true);
            const body = {
                isActive,
            };
            const response = await GroupServiceSDK.updateGroupStatus(id, body);

            if (!!response.success) {
                getGroups(page, {name: filterName, client: filterClient, membership: filterMembership });
            }
        } catch (error) {

        }
    }, [getGroups, filterName, filterClient, filterMembership]);

    //HANDLES 
    // -- Actions tables
    const handleChangePagination = (event, value) => {
        setPage(value);
        getGroups(value, {name: filterName, companyId: filterClient, membership: filterMembership});
    };
    // -- Evento para la seleccion del cliente.
    const handleChangeSelectClient = (value) => {
        setFilterClient(value);
        setFilterMembership("");
        getGroups(1, {name: filterName, client: value});
    };
    // -- Evento para la seleccion de estatus.
    const handleChangeSelectMembership = (value) => {
        setFilterMembership(value);
        getGroups(1, {name: filterName, client: filterClient, membership: value});
    };
    // -- Evento para cambiar el texto del filtro de busqueda por nombre.
    const handleChangeFilterTextValue = (value) => {
        setFilterName(value);
    };
    // -- Evento para eliminar todo el texto del filtro de busqueda por nombre.
    const handleClickDeleteFilterTextValue = () => {
        setFilterName("");
        getGroups(1, {name: "", client: filterClient, membership: filterMembership});
    };
    // -- Evento para cuando presionen enter en el campo de texto de los filtros.
    const handleKeyDownFilterTextValue = (text) => {
        getGroups(1, {name: text, client: filterClient, membership: filterMembership});
    };
    // -- Drawer create group
    const handleChangeOpenDrawer = (data) => {
        setOpenDrawer(data);
    };
    const handleChangeLoadingDrawer = () => {
        setLoadingDrawer(prevState => !prevState);
    };

    // -- Drawer update group
    const handleChangeOpenDrawerUpdate = (data) => {
        if (!data) setLoadingDrawerUpdate(false);
        setOpenDrawerUpdate(prevState => data ?? !prevState);
    };
    const handleChangeLoadingDrawerUpdate = () => {
        setLoadingDrawerUpdate(prevState => !prevState);
    };
    // -- Evento para cerrar los toast.
    const hanldeFinishedToast = () => {
        setAlertProps(prevState => ({ ...prevState, open: false, message: "", }));
    };

    // -- Evento para recargar la tabla
    const handleReloadTable = () => {
        getGroups(1, {name: filterName, client: filterClient, membership: filterMembership});
    };

    // -- Evento para confirmar el boton del modal
    const handleButtonConfirmModal = () => {
        changeGroupStatus(false, tagGroupModuleIdToDelete);
        setTagGroupModuleIdToDelete(0);
        setOpenModal(false);
    };

    // -- Evento para confirmar el boton del modal
    const handleButtonCancelModal = () => {
        setTagGroupModuleIdToDelete(0);
        setOpenModal(false);
    };
    // -- Evento para cerrar el modal
    const handleCloseModal = async () => {
        try {
            setOpenModal(false);
        } catch (error) {
            setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: error.message }));
        }
    };

    // EFFECTS
    React.useEffect(() => {
        const getClients = async () => {
            try {
                const response = await GroupServiceSDK.getClientsOrMemberships({ type: "clients" }, ac);

                if (!!response.success) {
                    setClients(response.data.clients.map(client => ({ id: client.company_id, label: client.name })));
                } else {
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                }
            } catch (error) {
                if (error.name === 'AbortError')
                    console.log('Error\n', error.message);
                else
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };
        getClients();
    }, []);

    React.useEffect(() => {
        const getMemberships = async () => {
            try {
                const response = await GroupServiceSDK.getClientsOrMemberships({ type: "memberships", companyId: filterClient }, ac);

                if (!!response.success) {
                    setMemberships(response.data.memberships.map(membership => ({ id: membership.membership_id, label: membership.name })));
                } else {
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                }
            } catch (error) {
                if (error.name === 'AbortError')
                    console.log('Error\n', error.message);
                else
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };
        getMemberships();
    }, [filterClient]);

    React.useEffect(() => {
        getGroups(1, {});
    }, [getGroups]);

    React.useEffect(() => {
        // -- Evento para duplicar la playlist.
        const handleClickDuplicate = async id => {
            try {
                setIsDuplicatingGroup(true);
                setOpenDrawerUpdate(true);
                setTagGroupModuleId(id);
            } catch (error)  {
                setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            };
            setLoadingTable(false);
        };
        // -- Evento que borra la playlist
        const handleClickDelete = (id, nameGroup) => {
            setModalProps(() => ({ iconClassname: "ri-save-2-fill text-dark-black-200", message: `¿Deseas eliminar “${nameGroup}”?` }));
            setOpenModal(true);
            setTagGroupModuleIdToDelete(id);
        };
        // -- Evento que abre la seccion para editar una playlist.
        const handleClickEdit = id => {
            setOpenDrawerUpdate(true);
            setTagGroupModuleId(id);
        };

        const data = dataGroups.map(group => ({
            tagGroupModuleId: group.tag_group_module_id,
            tagGroupId: group.tag_group_id,
            nameGroup: group.tag_group_name,
            membership: group.membership_name,
            client: group.company_name,
            users: group.users,
            tags: group.tags,
            actions: ''
        }));

        const groups = data.map((item, index) => {
            item.name = <Box display='flex' alignItems='center' gridGap={10}>
                <Icon className='ri-group-2-line text-dark-black-200' style={{ height: 24 }} />
                <Typography variant='body1' className='semi-bold'>{item.nameGroup}</Typography>
            </Box>;
            item.id = index;
            let options = [
                { id: 1, label: 'Duplicar', fun: () => handleClickDuplicate(item.tagGroupModuleId) },
                { id: 2, label: 'Editar grupo', fun: () => handleClickEdit(item.tagGroupModuleId) },
                { id: 3, label: 'Eliminar grupo', fun: () => handleClickDelete(item.tagGroupModuleId, item.nameGroup) },
            ];

            return {
                ...item,
                actions: <MoreOptions options={options} iconButtonProps={{ className: 'p-0 disable-hover' }} />
            }
        });
        setRows(groups);
    }, [dataGroups]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box display='flex' justifyContent='space-between'>
                                <Box>
                                    <Typography variant='h3' className='mb-2'>Grupos</Typography>
                                    <Typography variant='body2' className='text-dark-black-200'>Crea grupos para gestionar usuarios con las herramientas de administrador.</Typography>
                                </Box>
                                <Box>
                                    <DrawerButtonContainer
                                        title="Crear nuevo grupo"
                                        textBttn={"Crear nuevo grupo"}
                                        endIcon={<Icon className='ri-menu-add-fill' />}
                                        open={openDrawer}
                                        onClickButton={handleChangeOpenDrawer}
                                        loading={loadingDrawer}
                                    >
                                        <FormGroup onChangeLoadingDrawer={handleChangeLoadingDrawer} onChangeDrawer={handleChangeOpenDrawer} onReloadTable={handleReloadTable} />
                                    </DrawerButtonContainer>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <LoaderContent loading={loadingTable}>
                            <TableFilter>
                                <Grid item xs={6}>
                                    <InputSearch
                                        value={filterName}
                                        onKeyDown={handleKeyDownFilterTextValue}
                                        onChange={handleChangeFilterTextValue}
                                        onClickDelete={handleClickDeleteFilterTextValue}
                                        placeholder={"Buscar grupo"}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        label="Cliente"
                                        name="client"
                                        items={clients}
                                        value={filterClient}
                                        onChange={e => handleChangeSelectClient(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        label="Membresía"
                                        name="membership"
                                        items={memberships}
                                        value={filterMembership}
                                        onChange={e => handleChangeSelectMembership(e.target.value)}
                                    />
                                </Grid>
                            </TableFilter>
                            <Table
                                columns={[
                                    { name: 'name', label: 'Nombre del grupo' },
                                    { name: 'client', label: 'Cliente', align: 'center' },
                                    { name: 'membership', label: 'Membresía' },
                                    { name: 'users', label: 'Usuarios' },
                                    { name: 'tags', label: 'Tags' },
                                    { name: 'actions', label: 'Acciones', align: 'right' },
                                ]}
                                rows={rows}
                                loading={false}
                                className={classes.table}
                            />
                            <Pagination
                                results={rows.length}
                                totalResults={totalRows}
                                page={page}
                                count={!!totalRows && !!rows.length ? Math.ceil(totalRows / TOTAL_ROWS_TO_SEARCH_TABLE) : 1}
                                onChange={handleChangePagination}
                            />
                        </LoaderContent>
                    </Card>
                </Grid>
            </Grid>

            <EdithGroup
                loading={loadingDrawerUpdate}
                title="Editar grupo"
                open={openDrawerUpdate}
                onChangeLoadingDrawer={handleChangeLoadingDrawerUpdate}
                onChangeDrawer={handleChangeOpenDrawerUpdate}
                onReloadTable={handleReloadTable}
                tagGroupModuleId={tagGroupModuleId} 
                isDuplicatingGroup={isDuplicatingGroup}/>

            <Toast {...alertProps}
                onFinished={hanldeFinishedToast}
            />

            <ModalToConfirm modalProps={modalProps} onClickConfirmButton={handleButtonConfirmModal} onClickCancelButton={handleButtonCancelModal} onCloseModal={handleCloseModal} openModal={openModal}/>
        </>
    );
};

export default Group;