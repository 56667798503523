import React from 'react';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const Main = ({children}) => {
    const classes = useStyles();
    return (
        <Box display='flex' height={1}>
            <Header />
            <Sidebar />
            <main className={classes.content}>
            <div className={classes.drawerHeader} />
                {children}
            </main>
        </Box>
    );
};

export default Main;