import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import styled from 'styled-components';
import clsx from 'clsx';

// style
import useStyles from '../../assets/styles/components/moreOptions/moreOptionStyle';
import { Icon } from '@material-ui/core';

const ListItemIconStyled = styled(ListItemIcon)`
    min-width: 28px;
`
const ITEM_HEIGHT = 48;

export default function MoreOptions({options, onClickOption, iconButtonProps}) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [optionSelected, setOptionSelected] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOption = (option) => {
        setOptionSelected(option.id);
        handleClose();
        if (option.fun && typeof option.fun === 'function') {
            option.fun();
        }
        onClickOption(option);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                {...iconButtonProps}
            >
                <Icon className={clsx('ri-more-2-line', classes.iconButton)}/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '25ch',
                    },
                }}
            >
                { 
                    options.map((option, index) => (
                        <MenuItem key={`more-options-${index}`} selected={option.id === optionSelected} onClick={() => handleClickOption(option)} className={classes.menuItem}>
                            {option.icon && <ListItemIconStyled className={clsx('mr-2', classes.menuItem)}>{option.icon}</ListItemIconStyled>}
                            {option.label}
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
}

MoreOptions.defaultProps = {
    onClickOption: () => {},
};

MoreOptions.propTypes = {
    /**
     * Array of options
     */
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.element,
        fun: PropTypes.func,
      })).isRequired,
    /**
     * Optional click handler
     */
    onClickOption: PropTypes.func,
};
