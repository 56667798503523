import palette from '../palette';

export default {
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 8,
            '& fieldset': {
                borderWidth: '0.5px',
                borderColor: palette.colors.fillStroke[50],
            },
            '&:hover fieldset': {
                borderWidth: '0.5px',
                borderColor: palette.colors.fillStroke[50],
            },
            '&.Mui-focused fieldset': {
                borderWidth: 1,
                borderColor: palette.colors.violet[300]
            },
            "&.Mui-error": {
                '&': {
                    backgroundColor: palette.colors.electricRed[100]
                },
                '& input': {
                    color: palette.colors.error[300]
                },
                '& fieldset': {
                    borderColor: palette.colors.electricRed[200],
                },
                '&:hover fieldset': {
                    borderColor: palette.colors.electricRed[200],
                },
                '&.Mui-focused fieldset': {
                    borderWidth: 1,
                    borderColor: palette.colors.electricRed[200]
                }
            }
        },
        '& label': {
            color: `${palette.colors.darkBlack[200]} !important`,
            lineHeight: '16px'
        },
        '&.input-text-plain': {
            '& label':{
                color: `${palette.colors.darkBlack[200]} !important`
            },
            '& .MuiOutlinedInput-root':{
                backgroundColor: 'transparent',
                border: 'unset !important',
                '& input':{
                    backgroundColor: 'transparent !important',
                    padding: '0 !important'
                },
                '& fieldset':{
                    border: 'unset !important',
                }
            }
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
            transform: 'translate(0px, -12px)',
            fontWeight: 600,
            fontSize: 14,
        }
    }
};