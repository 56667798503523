import React from 'react';
import clsx from 'clsx';
import moment from 'moment';

// Material components
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Icon, TextField, Typography, makeStyles } from '@material-ui/core';

// Components
import CoverPreview from './components/CoverPreview';
import CourseSelection from './components/CourseSelection';
import ModalToConfirm from '../ModalToConfirm/ModalToConfirm';

// Shared components
import Select from '../../../design-system/components/Form/Select/Select';
import Picker from '../../../design-system/components/Form/Picker/Picker';
import LoaderContent from '../../../design-system/components/Loader/LoaderContent';
import Toast from '../../../design-system/components/Toast/Toast';

// @SDK
import { AcademiesService, GlobalService } from '@sdk-point/talisis';

const AcademiesServiceSDK = new AcademiesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const GlobalServiceSDK = new GlobalService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

// Constants
const INI_COURSE_URL = "";
const INI_COURSE_ID = 0;
const FORM_COURSES = "courses";
const FORM_GROUP = "group";
const FORM_CLIENT = "client";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.colors.gray[50]
        }
    },
    boxContainer: {
        padding: '16px 24px 122px 12px',
        margin: 'auto',
        width: 'auto',
    },
    boxActions: {
        position: 'fixed',
        bottom: 0,
        flexDirection: 'row',
        display: 'flex',
        width: 598,
        backgroundColor: theme.palette.colors.gray[200],
        zIndex: 1
    },
    boxButtonActions: {
        display: 'flex',
        padding: '24px',
        gap: '8px',
        width: '100%'
    },
}));

const FormPlaylist = ({ onChangeDrawer, onChangeLoadingDrawer, pathGroupId, onReloadTable }) => {
    const classes = useStyles();
    const ac = new AbortController();

    // CONSTANTS
    const ITEM_COURSE = { course_id: INI_COURSE_ID, course_url: INI_COURSE_URL };
    const INI_FORM = {
        [FORM_CLIENT]: "",
        [FORM_GROUP]: "",
        name: "",
        description: "",
        file: null,
        hasDate: false,
        date: null,
        numberOfCourses: 1,
        [FORM_COURSES]: [{...ITEM_COURSE}]
    };

    // STATES
    const [form, setForm] = React.useState({ ...INI_FORM });
    const [playlist, setPlaylist] = React.useState({});
    const [isValidCourses, setIsValidCourses] = React.useState(false);
    const [disabledValidCoursesButton, setDisabledValidCoursesButton] = React.useState(true);
    const [disabledCreatePlaylistButton, setDisabledCreatePlaylistButton] = React.useState(true);
    const [clients, setClients] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [alertProps, setAlertProps] = React.useState({
        open: false,
        severity: "success",
        duration: 4000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        message: "",
    });
    const [modalProps, setModalProps] = React.useState({
        iconClassname: "",
        message: ""
    });
    const [isLoadValidatingCourses, setIsLoadValidatingCourses] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    // FUNCTIONS
    const validateForm = React.useCallback(() => {
        return (!!form.name && !!form.client && form.group && isValidCourses);
    }, [form.name, form.client, form.group, isValidCourses]);

    const validateFormToArchived = () => {
        return (!!form.name && !!form.client && form.group);
    };

    // HANDLES
    // -- Evento para cambiar alguen dato del formulario.
    const handleChange = ({ name, value }) => {
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    // -- Evento cuando el checkbox de la hora cambie de valor.
    const handleChangeCheckboxDate = (event) => {
        setForm(prevState => ({
            ...prevState,
            hasDate: event.target.checked,
            date: null
        }));
    };
    //-- Evento cuando el selector de numero para el total de cursos cambia.
    const handleChangeNumberOfCourse = ({ name, value }) => {
        setForm((prevState) => {
            const newState = { ...prevState };
            const number = newState[name];

            if (value === "" || parseInt(value) > 20) return newState;

            newState[name] = value;

            if (value > number) {
                for (let i = 1; i <= value - number; i++) {
                    newState[FORM_COURSES].push(ITEM_COURSE);
                }
            }

            if (value < number) {
                newState[FORM_COURSES] = newState[FORM_COURSES].slice(0, value);
            }

            return newState;
        });
        setIsValidCourses(false);
    };
    // -- Evento para modificar los nombres de los cursos
    const handleChangeTextCourse = (event, index) => {
        const data = event.target.value;

        setForm((prevState) => {
            const newState = { ...prevState };

            if (!data) newState[FORM_COURSES][index] = ITEM_COURSE;
            if (!!data && isNaN(data)) newState[FORM_COURSES][index] = { course_id: INI_COURSE_ID, course_url: data };
            if (!!data && !isNaN(data)) newState[FORM_COURSES][index] = { course_id: parseInt(data), course_url: INI_COURSE_URL };

            return newState;
        });
        setIsValidCourses(false);
    };
    // -- Evento para mover las posiciones de los cursos.
    const handleDragCourses = (originIndex, destinationIndex) => {
        setForm((prevState) => {
            const newState = { ...prevState };
            const originCourse = newState[FORM_COURSES][originIndex];

            newState[FORM_COURSES].splice(originIndex, 1);
            newState[FORM_COURSES].splice(destinationIndex, 0, originCourse);

            return newState;
        });
    };
    // -- Evento para realizar la validacion de los cursos.
    const handleClickValidateCourses = async () => {
        try {
            if (!form[FORM_GROUP]) throw new Error("Selecciona un grupo");

            setIsLoadValidatingCourses(true);

            const body = { listCourses: form.courses.map((course, index) => ({ ...course, order: index + 1 })), tagGroupModuleId: form[FORM_GROUP], location: window.location.origin };
            const validateData = await AcademiesServiceSDK.validatePathChecklinks(body);

            if (!!validateData.success) {
                const courses = validateData.data.courses;
                handleChange({ name: FORM_COURSES, value: courses });
                setIsValidCourses(!courses.some(course => !course.isValid));
            } else {
                setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: validateData.error }));
            }
            setIsLoadValidatingCourses(false);
        } catch (error) {
            setIsLoadValidatingCourses(false);
            setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: error.message }));
        }
    };
    // -- Evento para cerrar los toast.
    const hanldeFinishedToast = () => {
        setAlertProps(prevState => ({ ...prevState, open: false, message: "", }));
    };
    // -- Evento para el proceso de la generacion de una nueva playlist.
    const handleClickCreatePlaylist = async (isDraftPlaylist = false) => {
        try {
            if (!isDraftPlaylist && !validateForm()) throw new Error("Datos incompletos.");
            if (!!isDraftPlaylist && !validateFormToArchived()) throw new Error("Datos incompletos.");

            onChangeLoadingDrawer();

            // Guardamos la imagen.
            const location = 'academy/image-routes';
            const fileResponse = !!form.file ? (await GlobalServiceSDK.uploadFile(form.file, location, 'academy-image-routes', null)).id : null;

            const pathName = form.name.trim();

            const body = { 
                tagGroupModuleId: form[FORM_GROUP], 
                pathName: pathName, 
                description: form.description, 
                fileId: fileResponse, 
                statusId: !!isDraftPlaylist ? 2 : 1,
                deadline: (!!form.date) ? form.date.format("YYYY-MM-DD") : null 
            };

            body.listCourses = isValidCourses ? form[FORM_COURSES].map((course, index) => ({ course_id: course.id, order: index + 1 })) : []; 
            
            if (!!pathGroupId) {
                body.pathGroupId = playlist.path_group_id;
            }

            const sdkResponse = !!pathGroupId ? await AcademiesServiceSDK.updatePlaylist(body) : await AcademiesServiceSDK.createPlaylist(body);

            if (!!sdkResponse.success) {
                setModalProps(() => ({ iconClassname: !!isDraftPlaylist ? "ri-save-2-fill text-dark-black-200" : "ri-checkbox-circle-fill text-electric-green-300", message: !!isDraftPlaylist ? `La Playlist “${pathName}” ha sido guardada como borrador con éxito.` : `La Playlist “${pathName}” ha sido publicada con éxito.` }));
                onReloadTable();
                setOpenModal(true);
            } else {
                setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: sdkResponse.error }));
            }
        } catch (error) {
            setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: error.message }));
        }
        onChangeLoadingDrawer();
    };
    // -- Evento para cerrar el modal.
    const handleCloseModal = async () => {
        try {
            setOpenModal(false);
        } catch (error) {
            setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: error.message }));
        }
    };
    // -- Evento para hacer submit del modal.
    const handleButtonModal = (type) => {
        onChangeDrawer(false);
        setForm({ ...INI_FORM });
        setOpenModal(false);
    };

    // EFFECTS
    React.useEffect(() => {
        const getClients = async () => {
            try {
                const response = await AcademiesServiceSDK.getClientOrGroup({ type: "clients" }, ac);

                if (!!response.success) {
                    setClients(response.data.clients.map(client => ({ id: client.company_id, label: client.name })));
                } else {
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                }
            } catch (error) {
                if (error.name === 'AbortError')
                    console.log('Error\n', error.message);
                else
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };

        const getPlaylist = async () => {
            try {
                const response = await AcademiesServiceSDK.getPathInfoPlaylist({ pathGroupId: pathGroupId }, ac);

                if (!!response.success) {
                    const playlistData = response.data;
                    setPlaylist(playlistData);
                    setForm(prevState => ({
                        ...prevState,
                        name: playlistData.name,
                        description: playlistData.description,
                        file: null,
                        hasDate: !!playlistData.deadline,
                        date: !!playlistData.deadline ? moment.utc(playlistData.deadline) : null,
                        numberOfCourses: !!playlistData.courses.length ? playlistData.courses.length : 1,
                        [FORM_COURSES]: !!playlistData.courses.length ? playlistData.courses.map(item => ({ course_id: item.course_id, course_url: "" })) : [ITEM_COURSE]
                    }));
                } else {
                    setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: response.error }));
                }
            } catch (error) {
                setAlertProps(prevState => ({ ...prevState, open: true, severity: 'error', message: error.message }));
            }
        };

        const getData = async () => {
            if (!!pathGroupId) await getPlaylist();
            await getClients();
            if(!!pathGroupId) onChangeLoadingDrawer();
        };

        getData();
        return () => ac.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const getGroups = async () => {
            try {
                if (!!form.client) {
                    handleChange({ name: FORM_GROUP, value: "" });
                    const response = await AcademiesServiceSDK.getClientOrGroup({ type: "groups", companyId: form.client }, ac);
                    if (!!response.success) {
                        setGroups(response.data.groups.map(group => ({ id: group.tag_group_module_id, label: group.name })));
                    } else {
                        setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: response.error }));
                    }
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    handleChange({ name: FORM_GROUP, value: null });
                    setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
                } else
                    console.log('Error\n', error.message);
            }
        };
        getGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.client]);

    React.useEffect(() => {
        const setClientForm = () => {
            try {
                if (!!clients && !!clients.length && !!playlist && !!Object.keys(playlist).length) {
                    handleChange({ name: FORM_CLIENT, value: playlist.company_id });
                }
                if (!!groups && !!groups.length && !!playlist && !!Object.keys(playlist).length) {
                    handleChange({ name: FORM_GROUP, value: playlist.tag_group_module_id });
                }
            } catch (error) {
                setAlertProps(prevAlertProps => ({ ...prevAlertProps, open: true, severity: 'error', message: error.message }));
            }
        };
        setClientForm();
    }, [clients, playlist, groups]);

    React.useEffect(() => {
        const validateCourses = () => {
            return !form[FORM_COURSES].some(course => (!course.course_id && !course.course_url));
        };

        setDisabledValidCoursesButton(!validateCourses());
        setDisabledCreatePlaylistButton(!validateForm());
    }, [form, validateForm]);

    return (
        <>
            <Grid container className={clsx(classes.boxContainer)} spacing={3}>
                <Grid item xs={12}>
                    <Select
                        label="Cliente"
                        onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                        value={form.client}
                        name={FORM_CLIENT}
                        items={clients}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        label="Grupo"
                        onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                        value={form.group}
                        name={FORM_GROUP}
                        items={groups}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.name}
                        placeholder='Nombre de la playlist'
                        name="name"
                        variant="outlined"
                        label="Nombre de la playlist"
                        fullWidth
                        onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.description}
                        placeholder='Descripción'
                        name="description"
                        variant="outlined"
                        label="Descripción"
                        fullWidth
                        onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        minRows={4}
                    />
                </Grid>

                <CoverPreview
                    path={!!playlist && playlist.file_path ? playlist.file_path : null}
                    onChangeFile={handleChange}
                    name="file" 
                    playlistName={form.name}
                    />

                <Grid item xs={12}>
                    <Divider className='bg-fill-stroke-50' />
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' flexDirection='column'>
                                <Typography className='text-dark-black-200 semi-bold' variant='body2'>Asignar fecha límite de cumplimiento</Typography>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={form.hasDate}
                                        onChange={handleChangeCheckboxDate}
                                        name="hasDate"
                                        color="primary"
                                        className='py-1'
                                    />}
                                    label={<Typography className='text-dark-black-200' variant='body2'>
                                        Fecha límite de cumplimiento obligatoria
                                    </Typography>}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Picker label='Fecha' value={form.date}
                                onChangeDate={handleChange}
                                name="date" disabled={!form.hasDate} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h4' className='text-dark-black-500'>Agregar cursos</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' className='text-dark-black-200 mb-2'>Selecciona la cantidad de cursos que formaran la playlist</Typography>
                            <Box maxWidth={109}>

                                <TextField
                                    type='number'
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <Icon className='ri-graduation-cap-line text-dark-black-200' />,
                                        inputProps: { min: 1, max: 20, pattern: "[0-9]" }
                                    }}
                                    value={form.numberOfCourses}
                                    name="numberOfCourses"
                                    onChange={(e) => handleChangeNumberOfCourse({ name: e.target.name, value: e.target.value })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' className='text-dark-black-200'>Añade los cursos que conformarán tu Playlist Academy, después haz click en el botón de Revisar selección, visualizarás un enlace al inferior de cada ID o URL que hayas ingresado por curso, de esta manera podrás confirmar que los cursos seleccionados sean los correctos.</Typography>
                        </Grid>

                        <Grid item xs={12} container spacing={2} className='mt-1'>
                            <LoaderContent loading={isLoadValidatingCourses}>
                                <Grid container spacing={2}>
                                    <CourseSelection courses={form.courses}
                                        onChangeText={handleChangeTextCourse}
                                        onDrag={handleDragCourses}
                                    />
                                    <Grid item xs={12}>
                                        <Button variant='contained' fullWidth endIcon={<Icon className='ri-eye-line' />} disabled={disabledValidCoursesButton} onClick={handleClickValidateCourses} >Validar selección</Button>
                                    </Grid>
                                </Grid>
                            </LoaderContent>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box className={classes.boxActions}>
                <Box className={classes.boxButtonActions}>
                    <Button variant='contained' fullWidth onClick={() => onChangeDrawer(false)}>Descartar</Button>
                    <Button variant='contained' fullWidth onClick={() => handleClickCreatePlaylist(true)}>Guardar borrador</Button>
                    <Button variant='contained' color='primary' fullWidth disabled={disabledCreatePlaylistButton} onClick={() => handleClickCreatePlaylist()}>Publicar playlist</Button>
                </Box>
            </Box>

            <Toast {...alertProps}
                onFinished={hanldeFinishedToast}
            />

            <ModalToConfirm modalProps={modalProps} onClickConfirmButton={handleButtonModal} onCloseModal={handleCloseModal} openModal={openModal}/>
        </>
    );
};

export default FormPlaylist;